<template>
  <div class="card-branco w-100">
    <div class="d-flex justify-content-between align-items-center">
      <h1>Meus usuários</h1>
      <CButton 
        class="d-flex align-items-center ver-mais"
        @click="visible = !visible"
      >
        Ver mais 
        <font-awesome-icon 
          class="ps-2" 
          icon="circle-plus"
        />
      </CButton>
    </div>
    <CCollapse :visible="visible">
      <CCard class="my-3">
        <CCardBody class="p-0">
          <div class="tabela">
            <table class="w-100">
              <col>
              <col>
              <col>
              <col>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Último login</th>
                  <th>Criação</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in lista.users?.slice(0, 4)"
                  :key="item.id"
                >
                  <td class="email">{{ item.name }}</td>
                  <td class="email">{{ item.email }}</td>
                  <td>{{ dateToField(item.last_login) }}</td>
                  <td>{{ dateToField(item.created_at) }}</td>
                </tr>
                <tr v-if="chamadasPendentes.lista">
                  <td colspan="3">
                    <font-awesome-icon
                      icon="spinner" 
                      class="spinner mt-3"
                    />
                    Carregando
                  </td>
                </tr>
                <tr v-else-if="erro">
                  <td colspan="3">
                    Erro: {{ erro }}
                  </td>
                </tr>
                <tr v-else-if="!lista.users.length">
                  <td colspan="3">
                    Nenhum resultado encontrado.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
      <router-link
        to="/usuarios"
        class="ver-mais"
      >
        Ver todos
      </router-link>
    </CCollapse>
  </div>
</template>



<script setup>
import dateToField from '@/helpers/dateToFiel';
import { useMeusUsuariosStore } from '@/store/meusUsuarios.store';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const meusUsuariosStore = useMeusUsuariosStore()
const { lista, chamadasPendentes, erro } = storeToRefs(meusUsuariosStore);
const visible = ref(false)

async function iniciar(){
  await meusUsuariosStore.buscarTudo()
}
iniciar()
</script>

<style scoped>
.email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
}

.ver-mais{
  background-color: transparent;
  border: none;
  text-wrap: nowrap;
  color: var(--roxo);
  font-weight: 500;
}

h1 {
  color: var(--roxo);
}
</style>