import { defineStore } from 'pinia';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const usePtamsStore = defineStore('ptams', {
  state: () => ({
    lista: [],
    listaAmostra: [],
    infoImovel: null,
    infoProprietario: null,
    imovelId: null,
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      lista: false,
      infoImovel: false,
      infoProprietario: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/properties`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });

        this.lista =  request.properties.data

        this.paginacao.current_page = request.properties.current_page;
        this.paginacao.last_page = request.properties.last_page;
        this.paginacao.per_page = request.properties.per_page;
        this.paginacao.total = request.properties.total;
        this.paginacao.links = request.properties.links;

      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarInfoImovel(id = 0) {
      this.chamadasPendentes.infoImovel = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/propertie/${id}`);
        this.infoImovel = resposta.propertie
        this.listaAmostra = resposta.propertie.amostras
        console.log(this.listaAmostra)
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.infoImovel = false;
    },

    async buscarProprietario(id = 0) {
      this.chamadasPendentes.infoProprietario = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/owner/${id}`);
        this.infoProprietario = resposta.owner
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.infoProprietario = false;
    },

    async anexarArquivosPtams(id, params){
      console.log(id, params)
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.upload(`${baseUrl}/properties-upload-files/${id}`, params);
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarAnaliseAvaliacao(id, params){
      console.log(id, params)
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/laudo-update/${id}`, params);
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        await this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async salvarProprietario(params = {}, id = null) {

      this.chamadasPendentes.infoProprietario = true;
      this.erro = null;
      let request
      try {
        if (id) {
          request = await this.requestS.put(`${baseUrl}/owner/${id}`, params);
          this.infoProprietario = request.owner
        } else {
          request = await this.requestS.post(`${baseUrl}/owner`, params);
        }
        return true;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.infoProprietario = false;
    },

    async excluirItem(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/properties/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async salvarAmostras(params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.post(`${baseUrl}/samples-ptam`, params);
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarItem(params = {}, id = null) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      let request;
      try {
        if (id) {
          request = await this.requestS.put(`${baseUrl}/propertie/${id}`, params);
        } else {
          request = await this.requestS.post(`${baseUrl}/propertie`, params);
          this.imovelId = request.propertie.id
        }
        this.chamadasPendentes.emFoco = false;
        return true;
      } catch (erro) {
        console.log(erro)
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
