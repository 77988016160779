import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useOpiniaoMercadoStore = defineStore('OpiniaoMercado', {
  state: () => ({
    lista: [],
    emFoco: null,
    filePath: "",
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      lista: false,
      emFoco: false,
      filePath: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/market-opinion`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });
        this.lista = request.marketOpinions.data

        this.paginacao.current_page = request.marketOpinions.current_page;
        this.paginacao.last_page = request.marketOpinions.last_page;
        this.paginacao.per_page = request.marketOpinions.per_page;
        this.paginacao.total = request.marketOpinions.total;
        this.paginacao.links = request.marketOpinions.links;

      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id = 0) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/market-opinion/${id}`);
        this.emFoco = resposta.marketOpinion
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async gerarPdf(id, layout){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const response = await this.requestS.post(`${baseUrl}/market-opinion-print/${id}`, layout);
        this.filePath = response.filePath
        return this.filePath
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarOpiniaoEmail(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const resposta = await this.requestS.post(`${baseUrl}/market-opinion-send/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Arquivo colocado para fila de envio');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarItem(params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = this.requestS.post(`${baseUrl}/market-opinion`, params)
        this.chamadasPendentes.emFoco = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },

    async salvarItemEditado(params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = this.requestS.put(`${baseUrl}/market-opinion`, params)
        this.chamadasPendentes.emFoco = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
