<template>
  <div>
    <form @submit.prevent="onSubmitFotoImovel">
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 w-100">
          <label for="file">Fotos do imóvel</label>
          <input id="file" name="file" type="file" class="inputtext mb-2" multiple @change="onFileChange">
          <div class="d-grid">
            <small>Utilize CTRL para selecionar mais de uma foto.</small>
            <small>Arquivos válidos: JPG, PNG e GIF</small>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button class="btnRoxo" :disabled="isSubmitting">
          Salvar
        </button>
        <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
      </div>
    </form>

    <div v-if="emFoco?.all_images?.length" class="d-flex flex-wrap gap-3 mt-5">
      <div v-for="(imagem, index) in emFoco?.all_images" :key="imagem.id" class="position-relative">
        <img :src="imagem.public_path" alt="Foto do imóvel" class="img-thumbnail">
        <button type="button" class="btnRoxo position-absolute top-0 end-0 px-3 py-2" @click="removeImage(index)">
          X
        </button>
        <form @submit.prevent="AtualizarFotoImovel(imagem.id)">
          <div>
            <div class="my-2 mt-3">
              <Field v-model="imagem.principal" :name="`${imagem.id}-principal`" type="checkbox" :value="true"
                :checked="imagem.principal" class="inputcheckbox" />
              <LabelFromYup name="principal" :schema="schema" class="ps-2 pb-0" />
            </div>

            <div class="mb-2">
              <Field v-model="imagem.legenda" :name="`${imagem.id}-legenda`" placeholder="Legenda" type="text"
                class="inputtext mb-2" />
            </div>
          </div>

          <div class="d-flex flex-column align-items-center mt-4">
            <button class="btnAmarelo" :disabled="isSubmitting">
              Salvar
            </button>
            <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { legenda as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useAnaliseMercadoStore } from '@/store/analiseMercado.store';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { ref } from 'vue';

const analiseMercadoStore = useAnaliseMercadoStore()
const { analiseId, emFoco } = storeToRefs(analiseMercadoStore);
const { isSubmitting, handleSubmit } = useForm();

const alertStore = useAlertStore();

const file = ref([])
const props = defineProps({
  pesquisaInteligenteId: {
    type: Number,
    default: 0,
  },
});

async function onFileChange(e) {
  const files = e.target.files;
  if (files.length) {
    file.value = [...files];
  }
}

function removeImage(index) {
  console.log(index)
}

async function onSubmitFotoImovel() {
  if (file.value.length === 0) {
    alertStore.error("Por favor, adicione pelo menos uma foto.");
    return;
  }

  const formData = new FormData();
  file.value.forEach((file) => {
    formData.append('file[]', file);
  });

  try {
    await analiseMercadoStore.anexarFoto(analiseId.value, formData)
    await analiseMercadoStore.buscarItem(analiseId.value);
    alertStore.success('Sucesso');
  } catch (error) {
    alertStore.error("Erro ao enviar as fotos");
  }
}

const AtualizarFotoImovel = handleSubmit(async (values, idFoto) => {

  // const formdata = new FormData();
  // formdata.append("legenda", values[`${idFoto.evt}-"legenda"`]);
  // formdata.append("principal", values[`${idFoto.evt}-"principal"`]);
  // try {
  //   console.log(props.analiseId);
  //   await analiseMercadoStore.atualizarFoto(formdata, props.analiseMercadoId, idFoto.evt)
  //   alertStore.success('Sucesso');
  // } catch (error) {
  //   alertStore.error(error);
  // }
})

async function iniciar() {
  if (props.analiseMercadoId) {
    await analiseMercadoStore.buscarItem(props.analiseMercadoId);
  }
}
iniciar()

</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.img-thumbnail {
  padding: .25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 8px;
  max-width: 356px;
  object-fit: cover;
  height: 300px;
}
</style>