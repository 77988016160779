<template> 
  <div>
    <div class="py-3 px-3 bordaAmarela mb-3">
      <p>SDFS</p>
      <p>Rua Engenheiro Alberto Monteiro de Carvalho,3 - Curitiba PR</p>
      <p>Área Total: 1231.23m2 Área Útil: 123.12m2</p>
    </div>
    <div class="tabela mb-3">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th />
            <th>
              Tipo
            </th>
            <th>
              Título
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('logradouro' || 'bairro')"
            >
              Endereço/Bairro
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('preco')"
            >
              Valor
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('area')"
            >
              Á. Priv.
            </th>
            <th>
              R$/m² total
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('quartos')"
            >
              Quartos
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('banheiros')"
            >
              Banheiros
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('anunciante')"
            >
              Anunciante
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in listaOrdenada"
            :key="item.id"
          >
            <td>
              <input 
                id="imovel_selecionado"
                type="checkbox"
                name="imovel_selecionado"
                :checked="checkboxStates[item._id] || false"
                @click="selecionaImovel(item._id, $event)"
              >
            </td>
            <td>{{ item.logradouro || item.bairro }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.titulo }}</td>
            <td>R${{ dinheiro(item.preco) }}</td>
            <td>{{ item.area + `m²` }}</td>
            <td>R${{ dinheiro(item.preco / item.area_total) }}</td>
            <td>{{ item.quartos }}</td>
            <td>{{ item.vagas }}</td>
            <td class="anunciante">{{ item.anunciante }}</td>
            <td class="tdBtnAcao">
              <button @click="selecionaItem(item)" class="icon">
                <font-awesome-icon icon="pen-to-square" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <strong
      style="color: var(--roxo)"
    >
      Fator de oferta
    </strong>
    <p class="py-2">
      * Os valores constantes nos imóveis em divulgação, são normalmente superiores ao
      valor real do imóvel, por isto torna-se necessário a aplicação de um redutor (FATOR DE OFERTA)
      para adequá-lo ao valor de mercado. Aplicando então o % de redução (FATOR DE OFERTA) ao imóvel divulgado.
    </p>
    <form
      class="d-flex gap-3 align-items-end mb-4"
      @submit.prevent=""
    >
      <div class="w-100">
        <select
          id="estado"
          class="inputtext"
          name="estado"
        >
          <option value="">
            Selecionar
          </option>
          <!-- <option
            v-for="item in Object.values()"
            :key="item.valor"
            :value="item.valor"
          >
            {{ item.nome }}
          </option> -->
        </select>
      </div>

      <button class="btnRoxo">
        Pesquisar
      </button>
    </form>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>