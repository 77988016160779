<template>
  <div class="card-branco">
    <router-link
      to="/clientes"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <h1 class="mb-3">
      {{ route?.meta?.título }}
    </h1>
    <form
      @submit.prevent="onSubmitClientes"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="name" 
            :schema="schema"
          />
          <Field
            name="name"
            placeholder="Seu nome"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.name }"
          />
          <ErrorMessage
            class="error-msg"
            name="name"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cpf" 
            :schema="schema"
            :required="true"
          />
          <Field 
            v-maska
            name="cpf"
            type="text"
            placeholder="CPF/CNPJ"
            class="inputtext"
            maxlength="15"
            data-maska="['###.###.###-##', '##.###.###/####-##']"
            :class="{ 'error': errors.cpf }"
          />
          <ErrorMessage
            class="error-msg"
            name="cpf"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="email" 
            :schema="schema"
          />
          <Field 
            name="email"
            placeholder="seu_email@provedor.com" 
            type="text" 
            class="inputtext"
            :class="{ 'error': errors.email }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="email"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3  w-100">
          <LabelFromYup 
            name="telefone_celular" 
            :schema="schema"
          />
          <Field 
            v-maska
            name="telefone_celular"
            type="text"
            placeholder="(00) 00000-0000"
            class="inputtext light mb-2"
            maxlength="15"
            data-maska="(##) #####-####"
            :class="{ 'error': errors.telefone_celular }"
          />
          <ErrorMessage
            class="error-msg"
            name="telefone_celular"
          />
        </div>

        <div class="align-items-center gap-3 d-flex w-100 my-2">
          <label>
            <Field
              name="tipo_pessoa"
              type="radio"
              value="Pessoa Física"
              class="inputcheckbox"
            />
            <span class="ps-2">Pessoa Física (Corretor)</span>
          </label>
          <label>
            <Field
              name="tipo_pessoa"
              type="radio"
              value="Pessoa Jurídica"
              class="inputcheckbox"
            />
            <span class="ps-2">Pessoa Jurídica(Imobiliária)</span>
          </label>
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="password" 
            :schema="schema"
          />
          <Field
            name="password" 
            placeholder="Nova senha" 
            type="password" 
            class="inputtext"
            :class="{ 'error': errors.password }"  
          />
          <ErrorMessage 
            class="error-msg" 
            name="password"
          />
        </div>
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="password_confirmation" 
            :schema="schema"
          />
          <Field
            name="password_confirmation" 
            placeholder="Senha atual" 
            type="password" 
            class="inputtext"
            :class="{ 'error': errors.password_confirmation }"  
          />
          <ErrorMessage 
            class="error-msg" 
            name="password_confirmation"
          />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          CRIAR USUÁRIO
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { novoCliente as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useClientesStore } from '@/store/clientes.store';
import { vMaska } from "maska/vue";
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';

const meusClientesStore = useClientesStore()

const router = useRouter();
const route = useRoute();
const alertStore = useAlertStore();

const {
  errors, isSubmitting, handleSubmit, values
} = useForm({
  validationSchema: schema,
});

const onSubmitClientes = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
    };

    const resposta = await meusClientesStore.salvarItem(payload); 
    if (resposta) {
      alertStore.success('Item adicionado com sucesso!');
      meusClientesStore.$reset();
      router.push({ name: "clientes" });
    }
  } catch (error) {
    alertStore.error(error);
  }
})
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}
</style>