<template>
  <div>
    <div class="pb-4">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <h1 class="pb-2">Pacote Individual</h1>
        <div class="d-flex justify-content-between align-items-center">
          <button
            class="btnBranco p-2"
            @click="abrirModalPacoteIndividual()"
          >
            <font-awesome-icon icon="fa-solid fa-circle-plus" />
            Adicionar pacote Individual
          </button>

          <button
            class="btnBranco p-2"
            @click="abrirModalAdicionalUsuario()"
          >
            <font-awesome-icon icon="fa-solid fa-circle-plus" />
            Adicional de Usuário
          </button>
        </div>
      </div>
      <table class="w-100 mb-3">
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Pacote</th>
            <th>Opiniões</th>
            <th>Pesquisa inteligente</th>
            <th>Análises de Avaliação</th>
            <th>Adesão</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in pacotes"
            :key="item.id"
          >
            <td class="descricao">{{ item.nome }}</td>
            <td>{{ item.quantidade_opinioes }}</td>
            <td>{{ item.quantidade_pic }}</td>
            <td>{{ item.quantidade_avaliacoes }}</td>
            <td>{{ item.created_at ? dateToField(item.created_at) : '-' }}</td>
            <td>{{ item.valor ? `R$${item.valor}` : '-' }}</td> 
          </tr>
          <tr v-if="chamadasPendentes.pacotes">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!pacotes.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="mostrarModalPacoteIndividual"
      class="modal fade show"
      style="display: block; background-color: rgba(0,0,0,0.5);" 
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header justify-content-between">
            <h3>Adicionar Pacote Individual</h3>
            <button 
              type="button" 
              class="Modalclose"
              @click="fecharModalPacoteIndividual"
              aria-label="Close"
            >
              <font-awesome-icon icon="circle-xmark" />
            </button>
          </div>
          <div class="modal-body">
            <Form
              v-slot="{ isSubmitting }"
              :validation-schema="schemaUsuarioAdicional"
              @submit="onSubmitPacoteIndividual"
            >
              <div 
                v-if="emFoco?.user.name"
                class="mb-3 w-100"
              >
                <LabelFromYup 
                  name="user_id" 
                  :schema="schemaPacote"
                />
                <p class="inputtext">{{emFoco?.user.name}}</p>
                <Field
                  :value="emFoco.user.id"
                  name="user_id"
                  type="text"
                  class="inputtext hidden-field"
                />
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup
                  name="plano_id"
                  :schema="schemaPacote"
                />
                <Field
                  name="plano_id"
                  as="select"
                  class="inputtext"
                >
                  <option value="">
                    Selecionar
                  </option>
                  <option
                    v-for="item in Object.values(pacotes)"
                    :key="item.valor"
                    :value="item.id"
                  >
                    {{ item.nome }}
                  </option>
                </Field>
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup
                  name="metodo_pagamento"
                  :schema="schemaPacote"
                />
                <Field
                  v-model="metodo_pagamento"
                  name="metodo_pagamento"
                  as="select"
                  class="inputtext"
                >
                  <option
                    v-for="item in Object.values(metodoPagamento)"
                    :key="item.valor"
                    :value="item.valor"
                  >
                    {{ item.nome }}
                  </option>
                </Field>
              </div>
              <div class="modal-footer py-0">
                <button 
                  class="btnBranco"
                  data-bs-dismiss="modal"
                >
                  Fechar
                </button>
                <button 
                  class="btnRoxo" 
                  :disabled="isSubmitting"
                >
                  Enviar
                </button>
                <font-awesome-icon 
                  v-show="isSubmitting" 
                  icon="spinner" 
                  class="spinner mt-3"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="mostrarModalAdicionalUsuario"
      class="modal fade show"
      style="display: block; background-color: rgba(0,0,0,0.5);" 
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header justify-content-between">
            <h3>Adicional de Usuário</h3>
            <button 
              type="button" 
              class="Modalclose"
              @click="fecharModalAdicionalUsuario"
              aria-label="Close"
            >
              <font-awesome-icon icon="circle-xmark" />
            </button>
          </div>
          <div class="modal-body">
            <Form
              v-slot="{ isSubmitting }"
              :validation-schema="schemaUsuarioAdicional"
              @submit="onSubmitAdicionalUsuario"
            >
              <div 
                v-if="emFoco?.user.name"
                class="mb-3 w-100"
              >
                <LabelFromYup 
                  name="user_id" 
                  :schema="schemaUsuarioAdicional"
                />
                <p class="inputtext">{{emFoco?.user.name}}</p>
                <Field
                  :value="emFoco.user.id"
                  name="user_id"
                  type="text"
                  class="inputtext hidden-field"
                />
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup
                  name="plano_id"
                  :schema="schemaUsuarioAdicional"
                />
                <Field
                  name="plano_id"
                  as="select"
                  class="inputtext"
                >
                  <option value="">
                    Selecionar
                  </option>
                  <option
                    v-for="item in Object.values(pacotesAdicionais)"
                    :key="item.valor"
                    :value="item.id"
                  >
                    {{ item.nome }}
                  </option>
                </Field>
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup
                  name="adicionais"
                  :schema="schemaUsuarioAdicional"
                />
                <Field
                  name="adicionais"
                  type="number"
                  class="inputtext"
                  min="1"
                  max="1000"
                />
              </div>

              <div class="mb-3 d-flex align-items-center">
                <Field
                  name="cobranca"
                  type="checkbox"
                  :value="true"
                  class="inputcheckbox"
                />
                <LabelFromYup
                  name="cobranca"
                  :schema="schemaUsuarioAdicional"
                  class="ps-2 pb-0"
                />
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup
                  name="metodo_pagamento"
                  :schema="schemaUsuarioAdicional"
                />
                <Field
                  v-model="metodo_pagamento"
                  name="metodo_pagamento"
                  as="select"
                  class="inputtext"
                >
                  <option
                    v-for="item in Object.values(metodoPagamento)"
                    :key="item.valor"
                    :value="item.valor"
                  >
                    {{ item.nome }}
                  </option>
                </Field>
              </div>
              <div class="modal-footer py-0">
                <button 
                  class="btnRoxo" 
                  :disabled="isSubmitting"
                >
                  Enviar
                </button>
                <font-awesome-icon 
                  v-show="isSubmitting" 
                  icon="spinner" 
                  class="spinner mt-3"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  criarPacote as schemaPacote,
  criarUsuarioAdicional as schemaUsuarioAdicional
} from '@/consts/formSchema';
import metodoPagamento from '@/consts/metodoPagamento';
import dateToField from '@/helpers/dateToFiel';
import { useAlertStore } from '@/store/alert.store';
import { useClientesStore } from '@/store/clientes.store';
import { storeToRefs } from 'pinia';
import { Field, Form } from 'vee-validate';
import { ref } from 'vue';

const meusClientesStore = useClientesStore()
const { emFoco, pacotes, pacotesAdicionais, chamadasPendentes, erro } = storeToRefs(meusClientesStore);

const alertStore = useAlertStore();

const mostrarModalPacoteIndividual = ref(false)
const mostrarModalAdicionalUsuario = ref(false)
const idSelecionado = ref(null);
const metodo_pagamento = ref('Boleto Bancário')
const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

function abrirModalPacoteIndividual() {
  idSelecionado.value = props.clienteId;
  mostrarModalPacoteIndividual.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalPacoteIndividual() {
  mostrarModalPacoteIndividual.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalAdicionalUsuario() {
  idSelecionado.value = props.clienteId;
  mostrarModalAdicionalUsuario.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalAdicionalUsuario() {
  mostrarModalAdicionalUsuario.value = false;
  document.body.style.overflow = 'auto';
}

async function onSubmitPacoteIndividual (values){
  console.log(values)
  try{
    const payload = {
      ...values,
    };

    const resposta = await meusClientesStore.criarPacote(payload);
    if(resposta){
      alertStore.success('Pacote Criado com Sucesso');
      meusClientesStore.buscarPacotesAdicionais()
      fecharModalPacoteIndividual()
    }
  } catch (erros){
    alertStore.error(erros.message); 
  }
}

async function onSubmitAdicionalUsuario (values){
  console.log(values)
  try{
    const payload = {
      ...values,
    };

    const resposta = await meusClientesStore.criarUsuarioAdicional(payload);
    if(resposta){
      alertStore.success('Adicionais Criado com Sucesso');
      meusClientesStore.buscarPacotesAdicionais()
      fecharModalAdicionalUsuario()
    }
  } catch (erros){
    alertStore.error(erros.message); 
  }
}

async function iniciar(){
  if (props.clienteId) {
    meusClientesStore.buscarPacotesAdicionais()
  }
}
iniciar()
</script>

<style scoped>
.descricao{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.btnBranco{
  color: var(--roxo)
}
</style>