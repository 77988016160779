<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Orçamentos</h1>
      <router-link
        :to="{ name: 'Orçamento criar' }"
        class="btnRoxo"
      >
        Novo Orçamento
      </router-link>
    </div>

    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="mw-100">
          <label>Solicitante</label>
          <input
            id="nome_solicitante"
            v-model.trim="nome_solicitante"
            placeholder="Solicitante"
            class="inputtext"
            name="nome_solicitante"
            type="text"
          >
        </div>

        <div>
          <label>E-mail</label>
          <input
            id="email_solicitante"
            v-model.trim="email_solicitante"
            placeholder="E-mail"
            class="inputtext"
            name="email_solicitante"
            type="text"
          >
        </div>

        <div>
          <label>Telefone</label>
          <input
            id="telefone_solicitante"
            v-model.trim="telefone_solicitante"
            placeholder="Telefone"
            class="inputtext"
            name="telefone_solicitante"
            type="text"
          >
        </div>

        <div>
          <label>Entregue</label>
          <select
            id="enviado"
            v-model.trim="enviado"
            class="inputtext"
            name="enviado"
          >
            <option value="" />
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>

    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>ID</th>
            <th>Solicitante</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Honorários</th>
            <th>Entregue</th>
            <th>Status</th>
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in lista"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.nome_solicitante }}</td>
            <td>{{ item.email_solicitante }}</td>
            <td>{{ item.telefone_solicitante }}</td>
            <td>R${{ dinheiro(item.valor_honorarios) }}</td>
            <td>{{ item.enviado ? "Sim" : "Não" }}</td>
            <td class="status">{{ item.aprovado ? item.aprovado : '-' }}</td>
            <td class="tdBtnAcao">
              <router-link
                :to="{ name: 'OrcamentoEditar', params: { orcamentoId: item.id } }"
                class="icon"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </router-link>
            </td>
            <td class="tdBtnAcao">
              <button
                class="icon"
                arial-label="excluir"
                @click="excluirOrcamento(item.id)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </td>
            <td v-if="item.notes" class="tdBtnAcao">
              <router-link
                :to="{ name: 'Comentários do orçamento', params: { orcamentoId: item.id }}"
                class="icon"
              >
                <font-awesome-icon
                  icon="comment"
                />
              </router-link>
            </td>
          </tr>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="paginacao" 
      class="d-flex gap-2 justify-content-center mt-4"
    >
      <button
        v-for="(link, index) in paginacao.links.slice(1, -1)"
        :key="index"
        class="paginacao-btn"
        :disabled="!link.url"
        :class="link.active ? 'paginacaoAtiva' : ''"
        @click="mudarPagina(link)"
      >
        {{ link.label }}
      </button>     
    </div>
  </div>
</template>

<script setup>
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useOrcamentoStore } from '@/store/orcamento.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const alertStore = useAlertStore();

const orcamentoStore = useOrcamentoStore()
const { lista, chamadasPendentes, erro, paginacao } = storeToRefs(orcamentoStore)

async function excluirOrcamento(id) {
  alertStore.confirmAction('Deseja mesmo remover esse item?', async () => {
    if (await orcamentoStore.excluirItem(id)) {
      orcamentoStore.$reset();
      orcamentoStore.buscarTudo();
      alertStore.success('Orçamento removido.');
    }
  }, 'Remover');
}

const nome_solicitante = ref(route.query.nome_solicitante)
const email_solicitante = ref(route.query.email_solicitante)
const telefone_solicitante = ref(route.query.telefone_solicitante)
const enviado = ref(route.query.enviado)

const consulta = {};
function atualizarUrl() {

  if (nome_solicitante.value) {
    consulta.nome_solicitante = nome_solicitante.value;
  }

  if (email_solicitante.value) {
    consulta.email_solicitante = email_solicitante.value;
  }

  if (telefone_solicitante.value) {
    consulta.telefone_solicitante = telefone_solicitante.value;
  }

  if (enviado.value) {
    consulta.enviado = enviado.value;
  }

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.nome_solicitante,
  () => route.query.email_solicitante,
  () => route.query.telefone_solicitante,
  () => route.query.enviado,
], () => {
  orcamentoStore.$reset();
  orcamentoStore.buscarTudo(consulta);
}, { immediate: true });

function limparFiltro(){
  nome_solicitante.value = '';
  email_solicitante.value = '';
  telefone_solicitante.value = '';
  enviado.value = '';

  router.push({
    query: {}
  });

  orcamentoStore.$reset();
  orcamentoStore.buscarTudo({});
}

async function mudarPagina(link) {
  if (link){
    await orcamentoStore.mudarPagina(link.label)
  }
}
</script>

<style scoped>
.status{
  text-transform: capitalize;
}
</style>