import { defineStore } from 'pinia';
import dataFormatada from '@/helpers/dataFormatada';
import { useAlertStore } from '@/store/alert.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useClientesStore = defineStore('clientes', {
  state: () => ({
    lista: [],
    planoAtual: [],
    assinaturas: [],
    pacotesAdicionais: [],
    pacotes: [],
    usuarioAdicional: [],
    emFoco: null,
    clienteEmFoco: null,
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      assinaturas: false,
      pacotesAdicionais: false,
      usuarioAdicional: false,
      pacotes: false,
      lista: false,
      planoAtual: false,
      emFoco: false,
      clienteEmFoco: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/user`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });
        this.lista =  request.user.data
        
        this.paginacao.current_page = request.user.current_page;
        this.paginacao.last_page = request.user.last_page;
        this.paginacao.per_page = request.user.per_page;
        this.paginacao.total = request.user.total;

        this.paginacao.links = request.user.links;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id = 0, params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.chamadasPendentes.clienteEmFoco = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/user/${id}`, params);
        this.emFoco = resposta
        this.clienteEmFoco = resposta.user
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
      this.chamadasPendentes.clienteEmFoco = false;
    },

    async buscarPlanoAtual(id = 0, params = {}) {
      this.chamadasPendentes.planoAtual = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/assinatura/ultima/${id}`, params);
        this.planoAtual = resposta.assinatura 
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.planoAtual = false;
    },

    async criarAssinatura(assinatura) {
      try {
        const payload = {
          ...assinatura,
        };
        await this.requestS.post(`${baseUrl}/admin/assinatura`, payload);
        return true
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async criarPacote(pacote) {
      try {
        const payload = {
          ...pacote,
        };
        await this.requestS.post(`${baseUrl}/admin/pacote`, payload);
        return true
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async buscarAssinaturas(id = 0) {
      this.chamadasPendentes.assinaturas = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/assinaturas/${id}`);
        this.assinaturas = resposta.assinaturas
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.assinaturas = false;
    },

    async criarUsuarioAdicional(usuarioAdicional) {
      try {
        const payload = {
          ...usuarioAdicional,
        };
        await this.requestS.post(`${baseUrl}/admin/adicional`, payload);
        return true
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async buscarUsuarioAdicional(id = 0) {
      this.chamadasPendentes.usuarioAdicional = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/admin/adicional`);
        this.usuarioAdicional = resposta
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.usuarioAdicional = false;
    },

    async buscarPacotesAdicionais() {
      this.chamadasPendentes.pacotesAdicionais = true;
      this.chamadasPendentes.pacotes = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/planos/pacotes`);
        this.pacotesAdicionais = resposta.adicionais
        this.pacotes = resposta.pacotes
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.pacotesAdicionais = false;
      this.chamadasPendentes.pacotes = false;
    },

    async atualizarPerfilUsuarios(user, id) {
      try {
        const payload = {
          ...user,
        };
        const request = await this.requestS.put(`${baseUrl}/auth/profile/${id}`, payload);
        console.log(request)
        return request
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async salvarItem(params = {}, id = null) {
      this.chamadasPendentes.emFoco = true;
      this.chamadasPendentes.clienteEmFoco = true;
      this.erro = null;
      console.log(params, id)
      let response
      try {
        if (id) {
         response = await this.requestS.put(`${baseUrl}/user/${id}`, params);
        } else {
          response = await this.requestS.post(`${baseUrl}/user`, params);
        }
        return response;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        this.chamadasPendentes.clienteEmFoco = false;
        return false;
      }
    },
  },
  getters: {
    itemParaEdicao: ({ clienteEmFoco }) => ({
      ...clienteEmFoco,
      nascimento: dataFormatada(clienteEmFoco?.nascimento),
    }),
  },
});
