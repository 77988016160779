<template>
  <div>
    <form
      @submit.prevent="onSubmitInformacaoProprietario"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 w-100">
          <LabelFromYup 
            name="nome" 
            :schema="schema"
          />
          <Field
            name="nome"
            placeholder="Nome do proprietário"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.nome }"
          />
          <ErrorMessage
            class="error-msg"
            name="nome"
          />
        </div>

        <div class="mb-2 w-100">
          <LabelFromYup 
            name="email" 
            :schema="schema"
            :required="true"
          />
          <Field 
            name="email"
            placeholder="E-mail" 
            type="text" 
            class="inputtext"
            :class="{ 'error': errors.email }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="email"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="celular" 
            :schema="schema"
          />
          <Field 
            v-maska
            name="celular"
            type="text"
            placeholder="(00) 00000-0000"
            class="inputtext"
            maxlength="15"
            data-maska="(##) #####-####"
            :class="{ 'error': errors.celular }"
          />
          <ErrorMessage
            class="error-msg"
            name="celular"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="telefone" 
            :schema="schema"
          />
          <Field 
            v-maska
            name="telefone"
            type="text"
            placeholder="(00) 00000-0000"
            class="inputtext"
            maxlength="15"
            data-maska="(##) #####-####"
            :class="{ 'error': errors.telefone }"
          />
          <ErrorMessage
            class="error-msg"
            name="telefone"
          />
        </div>       
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 d-flex align-items-center">
          <div>
            <LabelFromYup 
              name="cep"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="cep"
              v-maska
              name="cep"
              type="text"
              placeholder="CEP"
              class="inputtext"
              maxlength="9"
              data-maska="#####-###"
              :class="{ 'error': errors.cep }"
              @focusout="getCep"
            />
            <ErrorMessage
              class="error-msg"
              name="cep"
            />
          </div>
          <span v-if="chamadasPendentes.infoProprietario">
            <font-awesome-icon
              icon="spinner" 
              class="spinner mt-3"
            />
          </span>
        </div>

        <div class="mb-2 w-75">
          <LabelFromYup
            name="endereco" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.logradouro"
            name="endereco" 
            type="text" 
            placeholder="Rua" 
            class="inputtext"
            :class="{ 'error': errors.endereco }"
          />
          <ErrorMessage 
            class="error-msg"
            name="endereco"
          />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup 
            name="numero" 
            :schema="schema"
            :required="true"
          />
          <Field 
            name="numero" 
            type="text" 
            placeholder="Número" 
            class="inputtext"
            :class="{ 'error': errors.numero }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="numero"
          />
        </div>

        <div class="mb-2">
          <LabelFromYup 
            name="complemento" 
            :schema="schema"
          />
          <Field 
            name="complemento"
            type="text" 
            placeholder="Complemento" 
            class="inputtext light mb-2"
            :class="{ 'error': errors.complemento }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="complemento"
          />
        </div>

        
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-75">
          <LabelFromYup 
            name="bairro" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.bairro"
            name="bairro" 
            type="text" 
            placeholder="Bairro" 
            class="inputtext"
            :class="{ 'error': errors.bairro }"
          />
          <ErrorMessage 
            class="error-msg"
            name="bairro"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="estado" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.uf"
            name="estado" 
            type="text" 
            placeholder="Estado" 
            class="inputtext"
            :class="{ 'error': errors.estado }"
          />
          <ErrorMessage 
            class="error-msg"
            name="estado"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cidade"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.localidade"
            name="cidade" 
            type="text" 
            placeholder="cidade" 
            class="inputtext"
            :class="{ 'error': errors.cidade }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="cidade"
          />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { informacaoProprietario as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useCepStore } from '@/store/buscaCep.store';
import { usePtamsStore } from '@/store/ptams.store';
import { Tab } from 'bootstrap';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm, } from 'vee-validate';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();

const cepGet = useCepStore()
const { lista, chamadasPendentes  } = storeToRefs(cepGet);

const ptamsStore = usePtamsStore()
const { infoProprietario, imovelId } = storeToRefs(ptamsStore);

const router = useRouter();
const route = useRoute();
const props = defineProps({
  ptamsId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, isSubmitting, values, handleSubmit, resetForm
} = useForm({
  initialValues: infoProprietario.value,
  validationSchema: schema,
});

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
  } catch (error) {
    alertStore.error(error);
  }
}

const onSubmitInformacaoProprietario = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
      imovel_id: imovelId.value || props.ptamsId
    };

    const resposta = props.ptamsId
      ? await ptamsStore.salvarProprietario(payload, props.ptamsId)
      : await ptamsStore.salvarProprietario(payload);

    if( resposta ){
      const tabElement = document.getElementById('fotosImovel');
      const tabTrigger = new Tab(tabElement);
      tabTrigger.show();
    }

  } catch (error) {
    alertStore.error(error);
  }
})

watch(infoProprietario, (novoValor) => {
  resetForm({ values: novoValor });
});
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}
</style>