<template>
  <div>
    <div v-if="props.analiseMercadoId" class="d-flex justify-content-start gap-2 mb-2">
      <button 
        class="btnBranco"
        @click="abrirModalPdf"
        type="button"
      >
        <font-awesome-icon icon="file-pdf" />
        Gerar PDF
      </button>
      <button 
        class="btnBranco"
        data-bs-toggle="modal"
        data-bs-target="#modalEmailOpiniao"
        type="button"
      >
        <font-awesome-icon icon="envelope" />
        Enviar análise por e-mail
      </button>
      <button
        class="btnBranco"
        @click="abrirModalFinalizado(props.analiseMercadoId)"
      >
        <font-awesome-icon icon="check-to-slot" />
        Enviada/Finalizada
      </button>
      <div
        id="modalEmailOpiniao"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3> Enviar opinião de mercado por e-mail</h3>
              <button 
                type="button" 
                class="Modalclose"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form
                v-slot="{ errors, isSubmitting }"
                :validation-schema="schemaPtamsEmail"
                @submit="enviarOpiniao"
              >
                <div class="mb-4">
                  <LabelFromYup 
                    name="email"
                    :schema="schemaPtamsEmail"
                    :required="true"
                  />
                  <Field 
                    name="email" 
                    placeholder="seu_email@provedor.com" 
                    type="text" 
                    class="inputtext"
                    :class="{ 'error': errors.email }"
                  />
                  <ErrorMessage 
                    class="error-msg" 
                    name="email"
                  />
                </div>
                <div class="modal-footer py-0">
                  <button 
                    class="btnRoxo" 
                    :disabled="isSubmitting"
                  >
                    Enviar
                  </button>
                  <font-awesome-icon 
                    v-show="isSubmitting" 
                    icon="spinner" 
                    class="spinner mt-3"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="mostrarModalFinalizado"
        class="modal fade show"
        style="display: block; background-color: rgba(0,0,0,0.5);" 
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3>Enviada/Finalizada</h3>
              <button 
                type="button" 
                class="Modalclose"
                @click="fecharModalFinalizado"
                aria-label="Close"
              >
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form
                v-slot="{ errors, isSubmitting }"
                :validation-schema="SchemaFinalizado"
                @submit="enviarStatus"
              >
                <div class="mb-3 w-100">
                  <LabelFromYup
                    name="status"
                    :schema="SchemaFinalizado"
                    :required="true"
                  />
                  <Field
                    name="status"
                    as="select"
                    class="inputtext"
                    :class="{ 'error': errors.status }"
                  >
                    <option value="">
                      Selecionar
                    </option>
                    <option
                      v-for="item in Object.values(finalizado)"
                      :key="item.valor"
                      :value="item.valor"
                    >
                      {{ item.nome }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="status"
                    class="error-msg"
                  />
                </div>
                <div class="modal-footer py-0">
                  <button 
                    class="btnRoxo" 
                    :disabled="isSubmitting"
                  >
                    Enviar
                  </button>
                  <font-awesome-icon 
                    v-show="isSubmitting" 
                    icon="spinner" 
                    class="spinner mt-3"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="mostrarModalPdf"
        class="modal fade show"
        style="display: block; background-color: rgba(0,0,0,0.5);" 
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3>Escolha o template desejado</h3>
              <button 
                type="button" 
                class="Modalclose"
                @click="fecharModalPdf"
                aria-label="Close"
              >
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form
                v-slot="{ errors, isSubmitting }"
                :validation-schema="schemaLayout"
                @submit="enviarLayoutPdf"
              >
                <div class="mb-3 w-100">
                  <Field
                    v-model="layout"
                    name="layout"
                    as="select"
                    class="inputtext"
                    :class="{ 'error': errors.layout }"
                  >
                    <option value="">
                      Selecionar
                    </option>
                    <option
                      v-for="item in Object.values(layoutPdf)"
                      :key="item.valor"
                      :value="item.valor"
                    >
                      {{ item.nome }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="layout"
                    class="error-msg"
                  />
                </div>
                <div class="layout">
                  <div v-if="layout == 'default'">
                    <img src="../../assets/imagens/analise_mercadologica.png">
                  </div>
                  <div v-else-if="layout === 'clean'">
                    <img src="../../assets/imagens/analise.jpg">
                  </div>
                  <div v-else /> 
                </div>
                <div class="modal-footer py-0">
                  <button 
                    class="btnRoxo" 
                    :disabled="isSubmitting"
                  >
                    Enviar
                  </button>
                  <font-awesome-icon 
                    v-show="isSubmitting" 
                    icon="spinner" 
                    class="spinner mt-3"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-branco">
      <router-link
        :to="{ name: 'Análise de mercado' }"
        class="btnVoltar"
      >
        <font-awesome-icon
          icon="circle-left"
        />
      </router-link>
      <div class="d-flex justify-content-between align-items-center pb-4">
        <h1>{{ route?.meta?.título }}</h1>
      </div>
      <ul 
        id="pills-tab"
        class="nav nav-pills mb-4 gap-2" 
        role="tablist"
      >
        <button 
          id="informacaoImovel" 
          class="nav-link active"
          data-bs-toggle="pill"
          data-bs-target="#pills-home" 
          aria-selected="true"
        >
          Informações do imóvel e Análise
        </button>
        <button 
          class="nav-link"
          id="fotosImovel" 
          data-bs-toggle="pill" 
          data-bs-target="#fotoImovel"
          aria-selected="false"
        >
          Fotos do imóvel
        </button>
        <button 
          id="Cálculos" 
          class="nav-link" 
          data-bs-toggle="pill" 
          data-bs-target="#Calculos" 
          aria-selected="false"
        >
          Cálculo final
        </button>
        <button 
          id="Detalhe" 
          class="nav-link" 
          data-bs-toggle="pill" 
          data-bs-target="#Detalhes" 
          aria-selected="false"
        >
          Detalhes
        </button>
      </ul>
      <div 
        id="pills-tabContent" 
        class="tab-content"
      >
        <div 
          id="pills-home"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="informacaoImovel"
          tabindex="0"
        >
          <InformacoesimovelAnalise :analise-id="analiseMercadoId"/>
        </div>
        <div 
          id="Calculos"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="Cálculos"
          tabindex="0"
        >
          <Calculo :analise-id="analiseMercadoId"/>
        </div>
        <div 
          id="fotoImovel"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="fotosImovel"
          tabindex="0"
        >
          <FotosImovel :analise-id="analiseMercadoId"/>
        </div>
        <div 
          id="Detalhes"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="Detalhe"
          tabindex="0"
        >
          <Detalhes :analise-id="analiseMercadoId"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import finalizado from '@/consts/finalizado';
import {
  status as SchemaFinalizado,
  layout as schemaLayout,
  email as schemaPtamsEmail,
} from '@/consts/formSchema';
import layoutPdf from '@/consts/layoutPdf';
import { useAlertStore } from '@/store/alert.store';
import { useAnaliseMercadoStore } from '@/store/analiseMercado.store';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import Calculo from './Calculo.vue';
import Detalhes from './Detalhes.vue';
import FotosImovel from './FotosImovel.vue';
import InformacoesimovelAnalise from './InformacoesimovelAnalise.vue';

const alertStore = useAlertStore();
const route = useRoute();
const analiseMercadoStore = useAnaliseMercadoStore()

const mostrarModalFinalizado = ref(false)
const idSelecionado = ref(null);
const mostrarModalPdf = ref(false)
const layout = ref('default')

const props = defineProps({
  analiseMercadoId: {
    type: Number,
    default: 0,
  },
});

async function enviarOpiniao(email){
  try{
    if(props.analiseMercadoId){
      await analiseMercadoStore.enviarAnaliseEmail(
        props.analiseMercadoId, 
        email
      )
    }
  } catch(erro){
    alertStore.error(erro);
  }
}

function abrirModalPdf() {
  mostrarModalPdf.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalPdf() {
  mostrarModalPdf.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalFinalizado(id) {
  idSelecionado.value = id;
  mostrarModalFinalizado.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalFinalizado() {
  mostrarModalFinalizado.value = false;
  document.body.style.overflow = 'auto';
}

async function enviarLayoutPdf(value){
  try{
    if(props.analiseMercadoId){
      const resposta = await analiseMercadoStore.gerarPdf(props.analiseMercadoId, value)
      const pdfLink = resposta;
      window.open(pdfLink, '_blank');
      fecharModalPdf();
    } 
  } catch(erro){
    alertStore.error(erro);
  }
}

async function iniciar(){
  if (props.analiseMercadoId) {
    await analiseMercadoStore.buscarItem(props.analiseMercadoId);
  }
}
iniciar()

</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}
</style>