<template>
  <template v-if="!emFoco">
    <div class="d-flex">
      <font-awesome-icon
        icon="spinner" 
        class="spinner"
      />
      <h3>Carregando...</h3>
    </div>
  </template>
  <template v-else>
    <div>
      <div class="d-flex justify-content-start gap-2 mb-2">
        <button 
          class="btnBranco"
          @click="abrirModalPdf"
          type="button"
        >
          <font-awesome-icon icon="file-pdf" />
          Gerar PDF
        </button>
        <button 
          class="btnBranco"
          @click="abrirModalEmailOpiniao"
          type="button"
        >
          <font-awesome-icon icon="envelope" />
          Enviar opinião de mercado por e-mail
        </button>
        <button
          class="btnBranco"
          @click="abrirModalFinalizado(props.opiniaoMercadoId)"
        >
          <font-awesome-icon icon="check-to-slot" />
          Enviada/Finalizada
        </button>

        <div
          v-if="mostrarModal"
          class="modal fade show"
          style="display: block; background-color: rgba(0,0,0,0.5);" 
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header justify-content-between">
                <h3> Enviar opinião de mercado por e-mail</h3>
                <button 
                  type="button" 
                  class="Modalclose"
                  @click="fecharModalEmailOpiniao"
                  aria-label="Close"
                >
                  <font-awesome-icon icon="circle-xmark" />
                </button>
              </div>
              <div class="modal-body">
                <Form
                  v-slot="{ errors, isSubmitting }"
                  :validation-schema="schemaOpiniaoEmail"
                  @submit="enviarOpiniao"
                >
                  <div class="mb-4">
                    <LabelFromYup 
                      name="email"
                      :schema="schemaOpiniaoEmail"
                      :required="true"
                    />
                    <Field 
                      name="email" 
                      placeholder="seu_email@provedor.com" 
                      type="text" 
                      class="inputtext"
                      :class="{ 'error': errors.email }"
                    />
                    <ErrorMessage 
                      class="error-msg" 
                      name="email"
                    />
                  </div>
                  <div class="modal-footer py-0">
                    <button 
                      class="btnRoxo" 
                      :disabled="isSubmitting"
                    >
                      Enviar
                    </button>
                    <font-awesome-icon 
                      v-show="isSubmitting" 
                      icon="spinner" 
                      class="spinner mt-3"
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="mostrarModalPdf"
          class="modal fade show"
          style="display: block; background-color: rgba(0,0,0,0.5);" 
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header justify-content-between">
                <h3>Escolha o template desejado</h3>
                <button 
                  type="button" 
                  class="Modalclose"
                  @click="fecharModalPdf"
                  aria-label="Close"
                >
                  <font-awesome-icon icon="circle-xmark" />
                </button>
              </div>
              <div class="modal-body">
                <Form
                  v-slot="{ errors, isSubmitting }"
                  :validation-schema="schemaLayout"
                  @submit="enviarLayoutPdf"
                >
                  <div class="mb-3 w-100">
                    <Field
                      v-model="layout"
                      name="layout"
                      as="select"
                      class="inputtext"
                      :class="{ 'error': errors.layout }"
                    >
                      <option value="">
                        Selecionar
                      </option>
                      <option
                        v-for="item in Object.values(layoutPdf)"
                        :key="item.valor"
                        :value="item.valor"
                      >
                        {{ item.nome }}
                      </option>
                    </Field>
                    <ErrorMessage
                      name="layout"
                      class="error-msg"
                    />
                  </div>
                  <div class="layout">
                    <div v-if="layout == 'default'">
                      <img src="../../assets/imagens/padrao.jpg">
                    </div>
                    <div v-else-if="layout === 'clean'">
                      <img src="../../assets/imagens/clean.jpg">
                    </div>
                    <div v-else /> 
                  </div>
                  <div class="modal-footer py-0">
                    <button 
                      class="btnRoxo" 
                      :disabled="isSubmitting"
                    >
                      Enviar
                    </button>
                    <font-awesome-icon 
                      v-show="isSubmitting" 
                      icon="spinner" 
                      class="spinner mt-3"
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        
        <div
          v-if="mostrarModalFinalizado"
          class="modal fade show"
          style="display: block; background-color: rgba(0,0,0,0.5);" 
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header justify-content-between">
                <h3>Enviada/Finalizada</h3>
                <button 
                  type="button" 
                  class="Modalclose"
                  @click="fecharModalFinalizado"
                  aria-label="Close"
                >
                  <font-awesome-icon icon="circle-xmark" />
                </button>
              </div>
              <div class="modal-body">
                <Form
                  v-slot="{ errors, isSubmitting }"
                  :validation-schema="SchemaFinalizado"
                  @submit="enviarStatus"
                >
                  <div class="mb-3 w-100">
                    <LabelFromYup
                      name="status"
                      :schema="SchemaFinalizado"
                      :required="true"
                    />
                    <Field
                      name="status"
                      as="select"
                      class="inputtext"
                      :class="{ 'error': errors.status }"
                    >
                      <option value="">
                        Selecionar
                      </option>
                      <option
                        v-for="item in Object.values(finalizado)"
                        :key="item.valor"
                        :value="item.valor"
                      >
                        {{ item.nome }}
                      </option>
                    </Field>
                    <ErrorMessage
                      name="status"
                      class="error-msg"
                    />
                  </div>
                  <div class="modal-footer py-0">
                    <button 
                      class="btnRoxo" 
                      :disabled="isSubmitting"
                    >
                      Enviar
                    </button>
                    <font-awesome-icon 
                      v-show="isSubmitting" 
                      icon="spinner" 
                      class="spinner mt-3"
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-branco">
        <router-link
          to="/opnioesMercado"
          class="btnVoltar"
        >
          <font-awesome-icon
            icon="circle-left"
          />
        </router-link>
        <h1 class="mb-3">Visualizar Opinião de Mercado</h1>
        <div class="d-flex gap-4">
          <div 
            v-if="emFoco?.imovel_foto"
            class="imagem-imovel"
          >
            <img :src=emFoco?.imovel_foto>
          </div>
        </div>
        <div class="d-flex gap-4 flex-wrap mt-3">
          <div>
            <label>Endereço</label>
            <p>{{ emFoco?.endereco }}</p>
          </div>
          <div>
            <label>Tipo imovel</label>
            <p>{{ emFoco?.tipo_imovel }}</p>
          </div>
          <div>
            <label>Valor de divulgação</label>
            <p>R${{ dinheiro(emFoco?.valor_divulgacao) }}</p>
          </div>
          <div>
            <label>Tipo de Negócio</label>
            <p>{{ emFoco?.tipo_negocio }}</p>
          </div>
          <div>
            <label>Área Privativa</label>
            <p>{{ emFoco?.area_privativa ? emFoco?.area_privativa : "-" }}</p>
          </div>
          <div>
            <label>Data</label>
            <p>{{ dateToField(emFoco?.created_at) }}</p>
          </div>
        </div>
        <form
          class="pt-4"
          @submit="onSubmitAtualizarOpiniaoMercado"
        >
          <div>
            <label>Descrição</label>
            <QuillEditor
              id="imovel_descricao"
              v-model:content="imovel_descricao"
              content-type="html"
              theme="snow"
              class="mb-3"
              name="imovel_descricao"
            />   
          </div>
          <div class="mb-2 w-100">
            <label for="imovel_foto">Alterar fotos do imóvel</label>
            <input
              id="imovel_foto"
              name="imovel_foto"
              type="file" 
              class="inputtext mb-2"
              @change="onFileChange"
            >
            <small>Arquivos válidos: JPG, PNG e GIF</small>
          </div>

          <div>
            <LabelFromYup 
              name="valor_divulgacao" 
              :schema="schema"
            />
            <FloatMaskInput
              v-model="valor_divulgacao"
              :value="values.valor_divulgacao"
              name="valor_divulgacao" 
              type="text"
              placeholder="Valor divulgação" 
              class="inputtext mb-2"
              converter-para="string"
            />
            <ErrorMessage 
              class="error-msg"
              name="valor_divulgacao"
            />
          </div>

          <div class="d-flex flex-column align-items-center mt-4">
            <button 
              class="btnRoxo" 
              :disabled="isSubmitting"
            >
              SALVAR ALTERAÇÕES
            </button>
            <font-awesome-icon
              v-show="isSubmitting" 
              icon="spinner" 
              class="spinner mt-3"
            />
          </div>
        </form>
      </div>
      <div class="mt-4">
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>  
    </div>
  </template>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import finalizado from '@/consts/finalizado';
import {
  AmostraImovel as schema,
  status as SchemaFinalizado,
  layout as schemaLayout,
  email as schemaOpiniaoEmail,
} from '@/consts/formSchema';
import layoutPdf from '@/consts/layoutPdf';
import dateToField from '@/helpers/dateToFiel';
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useOpiniaoMercadoStore } from '@/store/opiniaoMercado.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const alertStore = useAlertStore();
const router = useRouter();

const opiniaoMercadoStore = useOpiniaoMercadoStore()
const { emFoco } = storeToRefs(opiniaoMercadoStore);

const mostrarModalFinalizado = ref(false)
const idSelecionado = ref(null);
const mostrarModal = ref(false)
const mostrarModalPdf = ref(false)
const imovel_foto = ref('')
const imovel_descricao = ref('')
const valor_divulgacao = ref('');
const layout = ref('default')
const props = defineProps({
  opiniaoMercadoId: {
    type: Number,
    default: 0,
  },
});

function abrirModalEmailOpiniao() {
  mostrarModal.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalEmailOpiniao() {
  mostrarModal.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalPdf() {
  mostrarModalPdf.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalPdf() {
  mostrarModalPdf.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalFinalizado(id) {
  idSelecionado.value = id;
  mostrarModalFinalizado.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalFinalizado() {
  mostrarModalFinalizado.value = false;
  document.body.style.overflow = 'auto';
}


const {
  resetForm, values,
} = useForm({
  initialValues: emFoco.value,
  validationSchema: schema,
});

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

async function onFileChange(e) {
  const file = e.target.files[0];
  if (file) {
    try {
      imovel_foto.value = await toBase64(file);
    } catch (error) {
      alertStore.error("Erro ao converter a imagem para base64");
    }
  }
}

async function onSubmitAtualizarOpiniaoMercado() {
  try {
    const payload = {
      imovel_foto: imovel_foto.value || emFoco.value.imovel_foto,
      imovel_descricao: imovel_descricao.value || emFoco.value.imovel_descricao,
      valor_divulgacao: valor_divulgacao.value || emFoco.value.valor_divulgacao,
      id: props.opiniaoMercadoId
    };
    
    const msg = 'Dados alterados com sucesso!'
    const resposta = await opiniaoMercadoStore.salvarItemEditado(payload);
    if (resposta) {
      alertStore.success(msg);
      opiniaoMercadoStore.$reset();
      router.push({ name: "Opiniões de mercado" });
    }
  } catch (error) {
    alertStore.error(error);
  }
}

async function enviarOpiniao(email){
  try{
    if(props.opiniaoMercadoId){
      await opiniaoMercadoStore.enviarOpiniaoEmail(props.opiniaoMercadoId, email)
      fecharModalEmailOpiniao();
    } 
  } catch(erro){
    alertStore.error(erro);
  }
}

async function enviarLayoutPdf(value){
  try{
    if(props.opiniaoMercadoId){
      const resposta = await opiniaoMercadoStore.gerarPdf(props.opiniaoMercadoId, value)
      const pdfLink = resposta;
      window.open(pdfLink, '_blank');
      fecharModalPdf();
    } 
  } catch(erro){
    alertStore.error(erro);
  }
}

async function iniciar(){
  if (props.opiniaoMercadoId) {
    await opiniaoMercadoStore.buscarItem(props.opiniaoMercadoId);
  }
}
iniciar()

watch(emFoco, (novoValor) => {
  if (novoValor) {
    resetForm({ values: novoValor });
    imovel_descricao.value = novoValor.imovel_descricao || '';
  }
}, { immediate: true });
</script>

<style scoped>
label{
  font-weight: bold
}

.ativo svg {
    background: initial; 
    color: var(--roxo); 
}

.imagem-imovel img{
  max-width: 250px;
  border-radius: 18px;
  width: 250px;
}
</style>