<template>
  <div class="cliente bordaCinza p-3 w-50">
    <h3 class="pb-3">
      Assinatura
    </h3>
    <ul class="m-0 p-0">
      <li>Plano: <strong>{{planoAtual?.plan_name}}</strong></li>
      <li>Data de Criação: <strong>{{ dateToField(planoAtual?.created_at)}}</strong></li>
      <li>Data de Expiração: <strong>{{ dateToField(planoAtual?.data_expiracao)}}</strong></li>
      <li>Proxima Cobrança a partir: <strong>{{dateToField(planoAtual?.proxima_cobranca)}}</strong></li>
      <li>Data de Validade do Plano: <strong>{{dateToField(planoAtual?.period_end_at)}}</strong></li>
      <li>Método de Pagamento: <strong>{{planoAtual?.metodo_pagamento}}</strong></li>
      <!-- <li>Criado Por: <strong>{{planoAtual?.creci}}</strong></li> -->
    </ul>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { useClientesStore } from '@/store/clientes.store';
import { storeToRefs } from 'pinia';

const meusClientesStore = useClientesStore()

const { planoAtual } = storeToRefs(meusClientesStore);
const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

async function iniciar(){
  if ( props.clienteId){
    await meusClientesStore.buscarPlanoAtual(props.clienteId)
  }
}
iniciar()
</script>

<style scoped>
.cliente li{
  list-style: none;
  color: var(--cinzaEscuro);
  text-decoration: none;
  padding-bottom: 7px;
}
</style>