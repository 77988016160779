<template>
  <div>
    <form
      @submit.prevent="onSubmitAvaliacao"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="data_vistoria"
            :schema="schema"
            :required="true"
          />
          <Field
            name="data_vistoria"
            type="date"
            class="inputtext light mb1"
            :class="{ 'error': errors.data_vistoria }"
            maxlength="10"
          />
          <ErrorMessage
            name="data_vistoria"
            class="error-msg"
          />
        </div>
        
        <div class="mb-3 w-100">
          <LabelFromYup
            name="data_avaliacao"
            :schema="schema"
            :required="true"
          />
          <Field
            name="data_avaliacao"
            type="date"
            class="inputtext light mb1"
            :class="{ 'error': errors.data_avaliacao }"
            maxlength="10"
          />
          <ErrorMessage
            name="data_avaliacao"
            class="error-msg"
          />
        </div> 

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="solicitante"
            :schema="schema"
          />
          <Field
            name="solicitante"
            type="text"
            placeholder="Solicitante"
            class="inputtext"
            :class="{ 'error': errors.solicitante }"
          />
          <ErrorMessage
            class="error-msg"
            name="solicitante"
          />
        </div>
      </div>

      <div>
        <label>Descrição da vistoria</label>
        <QuillEditor
          id="descricao_vistoria"
          v-model:content="descricao_vistoria"
          content-type="html"
          theme="snow"
          class="mb-3"
          name="descricao_vistoria"
        />   
      </div>

      <div>
        <label>Encerramento</label>
        <QuillEditor
          id="consideracoes_finais"
          v-model:content="consideracoes_finais"
          content-type="html"
          theme="snow"
          class="mb-3"
          name="consideracoes_finais"
        />   
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="finalidade"
            :schema="schema"
          />
          <Field
            name="finalidade"
            type="text"
            placeholder="finalidade"
            class="inputtext"
            :class="{ 'error': errors.finalidade }"
          />
          <ErrorMessage
            class="error-msg"
            name="finalidade"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="valor_consideracao_corretor" 
            :schema="schema"
          />
          <FloatMaskInput
            name="valor_consideracao_corretor" 
            type="text" 
            placeholder="Percepção de Valor do Perito Avaliador"
            :value="values.valor_consideracao_corretor"
            class="inputtext"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg" 
            name="valor_consideracao_corretor"
          />
        </div>

        <div class="d-flex w-100 gap-3 align-items-center">
          <div class="d-flex align-items-center">
            <Field
              name="exibir_curriculo"
              type="checkbox"
              :value="true"
              class="inputcheckbox"
            />
            <LabelFromYup
              name="exibir_curriculo"
              :schema="schema"
              class="ps-2 pb-0"
            />
          </div>
          
          <div class="d-flex align-items-center">
            <Field
              name="exibir_historico"
              type="checkbox"
              :value="true"
              class="inputcheckbox"
            />
            <LabelFromYup
              name="exibir_historico"
              :schema="schema"
              class="ps-2 pb-0"
            />
          </div>
        </div>     
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import { avaliacao as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { usePtamsStore } from '@/store/ptams.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm, } from 'vee-validate';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();
const ptamsStore = usePtamsStore()
const { infoImovel, imovelId } = storeToRefs(ptamsStore);

const router = useRouter();
const route = useRoute();

const descricao_vistoria = ref('');
const consideracoes_finais = ref('');

const props = defineProps({
  imovelVendidoId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, isSubmitting, values, handleSubmit,
} = useForm({
  // initialValues: infoImovel.value,
  validationSchema: schema,
});

const onSubmitAvaliacao = handleSubmit(async (values) => {
  try {
    const msg = 'Dados salvos com sucesso!'
    const payload = {
      ...values,
      consideracoes_finais: consideracoes_finais.value ,
      descricao_vistoria: descricao_vistoria.value,
    };

    const resposta = await ptamsStore.enviarAnaliseAvaliacao(imovelId.value, payload)
    if (resposta) {
      alertStore.success(msg);
      ptamsStore.$reset();
      ptamsStore.buscarTudo()
      // router.push({ name: "Análise de mercado" });
    }

  } catch (error) {
    alertStore.error(error);
  }
})

</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}
</style>