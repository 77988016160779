<template>
  <div class="card-branco">
    <router-link
      to="/usuarios"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <h1>{{ route?.meta?.título }}</h1>
    <form
      @submit.prevent="onSubmitUsuario"
    >
      <div class="form-group w-100">
        <div class="mb-4">
          <LabelFromYup 
            name="name" 
            :schema="schema"
          />
          <Field
            name="name"
            placeholder="Seu nome"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.name }"
          />
          <ErrorMessage
            class="error-msg"
            name="name"
          />
        </div>

        <div class="mb-4">
          <LabelFromYup 
            name="email" 
            :schema="schema"
          />
          <Field 
            name="email"
            placeholder="seu_email@provedor.com" 
            type="text" 
            class="inputtext"
            :class="{ 'error': errors.email }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="email"
          />
        </div>
      </div>

      <div class="form-group w-100">
        <div class="mb-3">
          <LabelFromYup 
            name="password" 
            :schema="schema"
          />
          <Field
            name="password" 
            placeholder="Nova senha" 
            type="password" 
            class="inputtext"
            :class="{ 'error': errors.password }"  
          />
          <ErrorMessage 
            class="error-msg" 
            name="password"
          />
        </div>
        <div class="mb-3">
          <LabelFromYup 
            name="password_confirmation" 
            :schema="schema"
          />
          <Field
            name="password_confirmation" 
            placeholder="Senha atual" 
            type="password" 
            class="inputtext"
            :class="{ 'error': errors.password_confirmation }"  
          />
          <ErrorMessage 
            class="error-msg" 
            name="password_confirmation"
          />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          CRIAR USUÁRIO
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { novoUsuario as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useMeusUsuariosStore } from '@/store/meusUsuarios.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';

const meusUsuariosStore = useMeusUsuariosStore()
const { emFoco } = storeToRefs(meusUsuariosStore);

const router = useRouter();
const route = useRoute();
const alertStore = useAlertStore();

const {
  errors, isSubmitting, handleSubmit, values
} = useForm({
  validationSchema: schema,
});

const onSubmitUsuario = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
    };

    const resposta = await meusUsuariosStore.salvarItem(payload); 
    console.log(resposta) 
    if (resposta) {
      alertStore.success('Item adicionado com sucesso!');
      meusUsuariosStore.$reset();
      router.push({ name: "usuarios" });
    }
  } catch (error) {
    alertStore.error(error);
  }
})
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}
</style>