import dataFormatada from '@/helpers/dataFormatada';
import { useAlertStore } from '@/store/alert.store';
import { defineStore } from 'pinia';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useMeusUsuariosStore = defineStore('MeusUsuarios', {
  state: () => ({
    lista: [],
    emFoco: null,
    chamadasPendentes: {
      lista: false,
      emFoco: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/my-children`, params);
        this.lista =  request
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id = 0, params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/user/${id}`, params);
        this.emFoco = resposta.user
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async atualizarPerfilUsuarios(user, id) {
      try {
        const payload = {
          ...user,
        };
        const request = await this.requestS.put(`${baseUrl}/auth/profile/${id}`, payload);
        return request
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async atualizarFotoPerfil(fotoPerfil, id) {
      try {
        const response = await this.requestS.put(
          `${baseUrl}/auth/profile/update-imagem-perfil/${id}`,
          fotoPerfil 
        );
        return response
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error('Erro ao atualizar a foto de perfil');
      }
    },
    
    async atualizarFotoLogo(fotoLogo, id) {
      try {
        const response = await this.requestS.put(
          `${baseUrl}/auth/profile/update-logo-avaliacao/${id}`,
          fotoLogo
        );
        return response
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error('Erro ao atualizar a foto de perfil');
      }
    },
    
    async atualizarFotoAssinatura(fotoAssinatura, id) {
      try {
        const response = await this.requestS.put(
          `${baseUrl}/auth/profile/update-imagem-assinatura/${id}`,
          fotoAssinatura
        );
        return response
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error('Erro ao atualizar a foto de perfil');
      }
    },

    async salvarItem(params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = await this.requestS.post(`${baseUrl}/new-child`, params);
        this.chamadasPendentes.emFoco = false;
        return request;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
  getters: {
    itemParaEdicao: ({ emFoco }) => ({
      ...emFoco,
      nascimento: dataFormatada(emFoco?.nascimento),
    }),
  },
});
