<template>
  <div>
    <div 
      v-if="props.ptamsId"
      class="d-flex justify-content-start gap-2 mb-2"
    >
      <a  
        :href="filePath" 
        class="btnBranco" 
        target="_blank"
      >
        <font-awesome-icon icon="file-pdf" />
        Gerar PDF
      </a>
      <button 
        class="btnBranco"
        data-bs-toggle="modal"
        data-bs-target="#modalEmailOpiniao"
        type="button"
      >
        <font-awesome-icon icon="envelope" />
        Enviar opinião de mercado por e-mail
      </button>
      <a  
        :href="filePath" 
        class="btnBranco" 
        target="_blank"
      >
        <font-awesome-icon icon="file-pdf" />
        Declaração
      </a>
      <button 
        class="btnBranco" 
        :disabled="isSubmitting"
        @click="enviarOpiniao"
      >
        <font-awesome-icon icon="file-circle-plus" />
        Enviada/Finalizada
      </button>
      <div
        id="modalEmailOpiniao"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3> Enviar opinião de mercado por e-mail</h3>
              <button 
                type="button" 
                class="Modalclose"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form
                v-slot="{ errors, isSubmitting }"
                :validation-schema="schemaPtamsEmail"
                @submit="enviarOpiniao"
              >
                <div class="mb-4">
                  <LabelFromYup 
                    name="email"
                    :schema="schemaPtamsEmail"
                    :required="true"
                  />
                  <Field 
                    name="email" 
                    placeholder="seu_email@provedor.com" 
                    type="text" 
                    class="inputtext"
                    :class="{ 'error': errors.email }"
                  />
                  <ErrorMessage 
                    class="error-msg" 
                    name="email"
                  />
                </div>
                <div class="modal-footer py-0">
                  <button 
                    class="btnBranco"
                    data-bs-dismiss="modal"
                  >
                    Fechar
                  </button>
                  <button 
                    class="btnRoxo" 
                    :disabled="isSubmitting"
                  >
                    Enviar
                  </button>
                  <font-awesome-icon 
                    v-show="isSubmitting" 
                    icon="spinner" 
                    class="spinner mt-3"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-branco">
      <router-link
        :to="{ name: 'Lista ptams' }"
        class="btnVoltar"
      >
        <font-awesome-icon
          icon="circle-left"
        />
      </router-link>
      <div class="d-flex justify-content-between align-items-center pb-4">
        <h1>{{ route?.meta?.título }}</h1>
      </div>
      <ul 
        id="pills-tab"
        class="nav nav-pills mb-4 gap-2" 
        role="tablist"
      >
        <button 
          id="informacaoImovel" 
          class="nav-link active"
          data-bs-toggle="pill"
          data-bs-target="#informacaoImoveis" 
          aria-selected="true"
        >
          Informações do imóvel
        </button>
        <button 
          id="proprietario" 
          class="nav-link" 
          data-bs-toggle="pill" 
          data-bs-target="#proprietarios" 
          aria-selected="false"
        >
          Proprietário
        </button>
        <button 
          class="nav-link"
          id="fotosImovel" 
          data-bs-toggle="pill" 
          data-bs-target="#fotoImovel"
          aria-selected="false"
        >
          Fotos do imóvel
        </button>
        <button 
          id="amostra" 
          class="nav-link" 
          data-bs-toggle="pill" 
          data-bs-target="#amostras"
          aria-selected="false"
        >
          Amostras
        </button>
        <button 
          id="calculo" 
          class="nav-link" 
          data-bs-toggle="pill" 
          data-bs-target="#calculos"
          aria-selected="false"
        >
          Cálculos
        </button>
        <button 
          id="avaliacao" 
          class="nav-link" 
          data-bs-toggle="pill" 
          data-bs-target="#avaliacoes"
          aria-selected="false"
        >
          Avaliação
        </button>
      </ul>
      <div 
        id="pills-tabContent" 
        class="tab-content"
      >
        <div 
          id="informacaoImoveis"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="informacaoImovel"
          tabindex="0"
        >
          <InformacaoImoveis :ptams-id="ptamsId" />
        </div>
        <div 
          id="proprietarios"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="proprietario"
          tabindex="0"
        >
          <InformacaoProprietario :ptams-id="ptamsId" />
        </div>
        <div 
          id="fotoImovel"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="fotosImovel"
          tabindex="0"
        >
          <FotosImovel :ptams-id="ptamsId" />
        </div>
        <div 
          id="amostras"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="amostra"
          tabindex="0"
        >
          <Amostras :ptams-id="ptamsId" />
        </div>
        <div 
          id="avaliacoes"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="avaliacao"
          tabindex="0"
        >
          <Avaliacao :ptams-id="ptamsId" />
        </div>
        <div 
          id="calculos"
          class="tab-pane fade" 
          role="tabpanel"
          aria-labelledby="calculo"
          tabindex="0"
        >
          <Calculo :ptams-id="ptamsId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { email as schemaPtamsEmail } from '@/consts/formSchema';
import { usePtamsStore } from '@/store/ptams.store';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';
import Amostras from './Amostras.vue';
import Avaliacao from './Avaliacao.vue';
import Calculo from './Calculo.vue';
import FotosImovel from './FotosImovel.vue';
import InformacaoImoveis from './InformacaoImoveis.vue';
import InformacaoProprietario from './informacaoProprietario.vue';

const ptamsStore = usePtamsStore()
const route = useRoute();

const props = defineProps({
  ptamsId: {
    type: Number,
    default: 0,
  },
});

async function iniciar() {
  if (props.ptamsId) {
    await ptamsStore.buscarInfoImovel(props.ptamsId); 
    await ptamsStore.buscarProprietario(props.ptamsId); 
  }
}
iniciar()
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: var(--cinzaClaro);
  color: var(--roxo);
  border: 1px solid var(--roxo);
  font-weight: bold;
}

.nav-link{
  color: var(--cinzaEscuro);
  background-color: var(--cinza);
}

.nav-link:focus, .nav-link:hover {
    color: var(--branco);
    background: var(--roxo);
}
</style>