import {
    object,
    string,
    boolean,
    ref,
    mixed,
    number
} from 'yup';
import genero from '@/consts/genero';
import profissoes from '@/consts/profissao';
import finalizado from './finalizado';
import estadosDoBrasil from './estadosDoBrasil';
import tipoOrcamento from './tipoOrcamento';
import tipoNegocio from './tipoNegocio';
import tipoImovel from './tipoImovel';
import raioBusca from './raioBusca';
import condicaoImovel from './condicaoImovel';
import creciRegiao from './creciRegiao';
import urbanoRural from './urbanoRural';
import layoutPdf from './layoutPdf';
import tipoPlano from './tipoPlano';
import metodoPagamento from './metodoPagamento';

export const autenticação = object()
.shape({
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha seu e-mail'),
  password: string()
    .label('Senha')
    .required('Preencha sua senha'),
});

export const cadastro = object()
.shape({
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha seu e-mail'),
  name: string()
    .label('Nome')
    .required('Nome é obrigatório'),
  password: string()
    .label('Senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Preencha sua senha'),
  password_confirmation: string()
    .label('Confirme sua senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Preencha sua senha'),
  telefone_celular: string()
    .label('Telefone')
    .required('O telefone é obrigatório'),
  cep: string()
    .label('CEP')
    .required('O CEP é obrigatório'),
  numero: string()
    .label('Número')
    .required('O numero é obrigatório'),
  endereco: string()
    .label('Endereço')
    .required('O Endereço é obrigatório'),
  bairro: string()
    .label('Bairro')
    .required('O bairro é obrigatório'),
  cidade: string()
    .label('Cidade')
    .required('O cidade é obrigatório'),
  estado: string()
    .label('Estado')
    .required('O estado é obrigatório'),
  complemento: string()
    .label('Complemento')
    .nullable(),
  termos: boolean()
    .label('Concordo com os')
    .required('Concorde com os Termos'),
});

export const email = object()
.shape({
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha seu e-mail'),
});

export const layout = object()
.shape({
  layout: mixed()
  .label('Layout do PDF')
  .oneOf([...Object.keys(layoutPdf), null])
});

export const status = object()
.shape({
  status: mixed()
  .label('Status')
  .oneOf([...Object.keys(finalizado), null])
  .required('Escolha uma opção')
});


export const meuPerfil = object()
.shape({
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha seu e-mail'),
  name: string()
    .label('Nome')
    .required('obrigatório'),
  telefone_celular: string()
    .label('Telefone')
    .required('Obrigatório'),
  cep: string()
    .label('CEP')
    .required('Obrigatório'),
  numero: string()
    .label('Número')
    .required('Obrigatório'),
  endereco: string()
    .label('Endereço')
    .required('Obrigatório'),
  bairro: string()
    .label('Bairro')
    .required('Obrigatório'),
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  estado: string()
    .label('Estado')
    .required('Obrigatório'),
  complemento: string()
    .label('Complemento')
    .nullable(),
  nascimento: string()
    .label('Nascimento')
    .required('Obrigatório'),
  cpf: string()
    .label('CPF')
    .required('Obrigatório'),
  rg: string()
    .label('RG')
    .nullable(),
  sexo: mixed()
    .label('Sexo')
    .oneOf([...Object.keys(genero), null]),
  creci: mixed()
    .label('Creci / Região')
    .nullable(),
  profissao: mixed()
    .label('Profissão'),
  numero_creci: string()
    .nullable()
    .label('Número creci'),
  cnai: string()
    .nullable()
    .label('CNAI'),
  cor_perfil: string()
    .nullable()
    .label('Cor do Perfil'),
  cor_text_perfil: string()
    .label('Cor do Texto')
    .nullable(),
  tabela_honorarios: string()
    .nullable()
    .label('Tabela de Honorários'),
  tipo_pessoa: mixed()
    .oneOf([
      'Pessoa Física',
      'Pessoa Jurídica',
    ])
    .nullable()
});

export const novaSenhaPerfil = object()
.shape({
  old_password: string()
  .label('Senha atual')
  .required('Preencha sua senha'),
  password: string()
    .label('Nova senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Preencha sua senha'),
  password_confirmation: string()
    .label('Confirmar nova senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .oneOf([ref('password')], 'As senhas devem coincidir')
    .required('Preencha sua senha'),
});

export const novoUsuario = object()
.shape({
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha seu e-mail'),
  name: string()
    .label('Nome')
    .required('obrigatório'),
  password: string()
    .label('Nova senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Preencha sua senha'),
  password_confirmation: string()
    .label('Confirmar nova senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Preencha sua senha'),
});

export const novoBairro = object()
.shape({
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  estado: mixed()
    .label('Estado')
    .oneOf([...Object.keys(estadosDoBrasil), null]),
  nome: string()
    .label('Nome')
    .required('obrigatório'),
  fonte: string()
    .label('Fonte da Informação')
    .required('obrigatório'),
});

export const bairro = object()
.shape({
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  estado: mixed()
    .label('Estado')
    .oneOf([...Object.keys(estadosDoBrasil), null]),
});

export const novoOrcamento = object()
.shape({
  tipo_orcamento: mixed()
    .label('Tipo de orçamento')
    .oneOf([...Object.keys(tipoOrcamento), null])
    .required('Obrigatório'),
  nome_solicitante: string()
    .label('Nome do Solicitante')
    .required('Obrigatório'),
  email_solicitante: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Obrigatório'),
  cpf: string()
    .label('CPF')
    .required('Obrigatório'),
  cep: string()
    .label('CEP')
    .required('Obrigatório'),
  estado: string()
    .label('Estado')
    .required('Obrigatório'),
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  bairro: string()
    .label('Bairro')
    .required('obrigatório'),
  address: string()
    .label('Endereço')
    .required('Obrigatório'),
  telefone_solicitante: string()
    .label('Telefone')
    .required('Obrigatório'),
  documentacao_necessaria: string()
    .label('Documentos Necessários')
    .required('Obrigatório'),
  valor_honorarios: string()
    .label('Valor dos Honorários')
    .nullable()
    .required('Obrigatório'),
  forma_pagamento: string()
    .label('Forma de Pagamento')
    .required('obrigatório'),
  prazo_execucao: string()
    .label('Prazo de Execução')
    .required('Obrigatório'),
  validade_proposta: string()
    .label('Validade desta Proposta')
    .required('Obrigatório'),
  nome_banco: string()
    .label('Banco')
    .nullable(),
  conta_pagamento: string()
    .label('Conta para pagamento')
    .nullable(),
  agencia_pagamento: string()
    .label('Agência para pagamento')
    .nullable(),
  chave_pix: string()
    .label('Chave Pix')
    .nullable(),
  titular: string()
    .label('Titular da conta')
    .nullable(),
});

export const novaOpiniaoMercado = object()
.shape({
  endereco: string()
    .required('Obrigatório'),
  // cep: string()
  //   .required('Obrigatório'),
  cidade: string()
    .required('Obrigatório'),
  estado: string()
    .required('Obrigatório'),
  bairro: string(),
  tipo_negocio: mixed()
    .label('Tipo de negócio')
    .oneOf([...Object.keys(tipoNegocio)])
    .required('Obrigatório'),
  tipo_imovel: mixed()
    .label('Tipo de imóvel')
    .required('Obrigatório'),
  radius: mixed()
    .label('Raio de busca')
    .required('Obrigatório'),
  area_privativa: number()
    .label('Área (m2)')
    .nullable(),
  imprimir_links: boolean()
    .label('Imprimir Links das Amostras no PDF')
    .nullable(),
  exibir_historico: boolean()
    .label('Exibir Currículo no PDF')
    .nullable(),
  exibir_curriculo: boolean()
    .label('Imprimir Histórico da Imobiliária no PDF')
    .nullable(),
  exibir_imoveis_realizados: boolean()
  .label('Imprimir Imóveis Vendidos no PDF')
  .nullable(),
  exibir_amostras_na_rua: boolean()
  .label('Exibir Imóveis na rua')
  .nullable(),
  usarArea: mixed()
  .oneOf([
    'area_util',
    'area_total',
  ])
  .nullable(),
  valor_divulgacao : number()
  .label('Adicionar valor de divulgação')
  .nullable(),
});

export const AmostraImovel = object()
.shape({
  valor_divulgacao : number()
  .label('Alterar valor de divulgação')
  .nullable(),
});

export const opiniaoMercadoEmail = object()
.shape({
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha o e-mail'),
});

export const novoImovel = object()
.shape({
  condicao: mixed()
    .label('Condição')
    .required('Obrigatório')
    .oneOf([...Object.keys(condicaoImovel)]),
  tipo_imovel: mixed()
    .label('Tipo de imóvel')
    .oneOf([...Object.keys(tipoImovel)])
    .required('Obrigatório'),
  endereco: string()
    .label('Endereço')
    .required('Obrigatório'),
  cep: string()
    .label('CEP')
    .required('Obrigatório'),
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  estado: string()
    .label('Estado')
    .required('Obrigatório'),
  bairro: string()
    .label('Bairro')
    .required('obrigatório'),
  numero: string()
    .label('Número')
    .nullable(),
  idade: string()
    .label('Idade')
    .nullable(),
  nome_edificio: string()
    .label('Nome do edifício')
    .nullable(),
  valor_fechamento: string()
    .label('Valor de fechamento')
    .nullable()
    .required('obrigatório'),
  valor_area_total_m2: string()
    .label('R$M² Total')
    .required('obrigatório')
    .nullable(),
  valor_area_privativa_m2: string()
    .label('R$M² Privativa')
    .nullable(),
  valor_condominio: string()
    .label('Valor do condomínio')
    .nullable(),
  dormitorios : number()
    .label('Número de Quartos')
    .nullable(),
  suites : number()
    .label('Número de Suítes')
    .nullable(),
  vagas_garagem : number()
    .label('Número de Vagas')
    .nullable(),
  data_de_divulgacao: string()
    .label('Data de Divulgação')
    .nullable(),
  data_de_venda: string()
    .label('Data da venda')
    .nullable(),
  valor_area_total: string()
    .label('Área total')
    .nullable()    
    .required('obrigatório'),
  valor_area_privativa: string()
    .label('Área privativa')
    .nullable(),
  valor_avaliacao: string()
    .label('Valor da avaliação')
    .nullable(),
  valor_divulgacao: string()
    .label('Valor de divulgação')
    .nullable(),
});

export const informacaoImovel = object()
.shape({
  titulo: string()
    .label('titulo'),
  tipo_regiao: mixed()
    .label('Tipo de região')
    .oneOf([...Object.keys(urbanoRural)])
    .required('Obrigatório'),
  tipo_imovel: mixed()
    .label('Tipo de imóvel')
    .oneOf([...Object.keys(tipoImovel)])
    .required('Obrigatório'),
  orcamento: mixed()
    .label('Orçamento Vínculado')
    .oneOf([...Object.keys(urbanoRural)]),
  tipo_negocio: mixed()
    .label('Tipo de negócio')
    .oneOf([...Object.keys(tipoNegocio)])
    .required('Obrigatório'),
  endereco: string()
    .label('Endereço')
    .required('Obrigatório'),
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  cep: string()
    .label('CEP')
    .required('Obrigatório'),
  numero: string()
    .label('Número')
    .required('Obrigatório'),
  longitude: string()
    .label('Longitude')
    .required('Obrigatório'),
  latitude: string()
    .label('Latitude')
    .required('Obrigatório'),
  estado: string()
    .label('Estado')
    .required('Obrigatório'),
  bairro: string()
    .label('Bairro')
    .required('obrigatório'),
  cartorio: string()
    .label('Cartório'),
  matricula: string()
    .label('Matrícula do Imóvel'),
  area_construida_m2: number()
    .label('Área construída (m²)')
    .nullable(),
  valor_area_construida_m2: number()
    .label('Valor m² área construída')
    .nullable(),
  valor_condominio: number()
    .label('Valor do condomínio')
    .nullable(),
  valor_iptu: number()
    .label('Valor do IPTU Por Ano')
    .nullable(),
  dormitorios : number()
    .label('Número de Quartos')
    .nullable(),
  suites : number()
    .label('Número de Suítes')
    .nullable(),
  numero_banheiros : number()
    .label('Número de Banheiros')
    .nullable(),
  vagas_garagem : number()
    .label('Número de Vagas')
    .nullable(),
  idade_imovel: string()
    .label('Idade do Imóvel')
    .nullable(),
  andar_imovel: string()
    .label('Andar do Imóvel')
    .nullable(),
  area_total: string()
    .label('Área Total(m²)')
    .nullable()    
    .required('obrigatório'),
  area_util: string()
    .label('Área Útil(m²)')
    .nullable(),
  area_construida: boolean()
    .label('Utilizar Área Construída')
    .nullable(),
});


export const informacaoProprietario = object()
.shape({
  nome: string()
    .label('Nome'),
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha o e-mail'),
  endereco: string()
    .label('Endereço')
    .required('Obrigatório'),
  numero: string()
    .label('Número')
    .required('O numero é obrigatório'),
  complemento: string()
    .label('Complemento')
    .nullable(),
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  cep: string()
    .label('CEP')
    .required('Obrigatório'),
  estado: string()
    .label('Estado')
    .required('Obrigatório'),
  bairro: string()
    .label('Bairro')
    .required('obrigatório'),
 celular: string()
    .label('Celular'),
  telefone: string()
    .label('Telefone')
});

export const avaliacao = object()
.shape({
  data_vistoria: string()
    .label('Data da Vistoria')
    .nullable(),
  data_avaliacao: string()
    .label('Data de Entrega da Avaliação')
    .nullable(),
  finalidade: string()
    .label('Finalidade'),
  solicitante: string()
    .label('Solicitante'),
  valor_consideracao_corretor: number()
    .label('Percepção de Valor do Perito Avaliador')
    .nullable(),
  exibir_curriculo: boolean()
    .label('Exibir currículo')
    .nullable(),
  exibir_historico: boolean()
    .label('Exibir histórico')
    .nullable(),
});

export const amostras = object()
.shape({
  rural_urbano: mixed()
    .label('Urbano ou Rural')
    .oneOf([...Object.keys(urbanoRural)])
    .required('Obrigatório'),
  tipo: mixed()
      .label('Tipo de imóvel')
      .oneOf([...Object.keys(tipoImovel)])
      .required('Obrigatório'),
  anuncioUrl: string()
    .label('Link do Anúncio')
    .required('obrigatório'),
  anunciante: string()
    .label('Anunciante')
    .required('Obrigatório'),
  cep: string()
    .label('CEP')
    .required('Obrigatório'),
  estado: string()
    .label('Estado')
    .required('Obrigatório'),
  cidade: string()
    .label('Cidade')
    .required('Obrigatório'),
  bairro: string()
    .label('Bairro')
    .required('obrigatório'),
  logradouro: string()
    .label('Endereço')
    .required('Obrigatório'),
  numero: number()
    .label('Numero')
    .required('obrigatório'),
  longitude: string()
    .label('Longitude')
    .required('Obrigatório'),
  latitude: string()
    .label('Latitude')
    .required('Obrigatório'),
  tipo_negocio: mixed()
    .label('Tipo de negócio')
    .oneOf([...Object.keys(tipoNegocio)])
    .required('Obrigatório'),
  titulo: string()
    .label('titulo')
    .required('obrigatório'),
  preco: number()
    .label('Valor total')
    .nullable()
    .required('obrigatório'),
  area_total: string()
    .label('Área Total(m²)')
    .nullable()    
    .required('obrigatório'),
  preco_metro: number()
    .label('Valor m² área construída')
    .nullable()
    .required('obrigatório'),
  condominio: number()
    .label('Valor do condomínio')
    .nullable(),
  iptu: number()
    .label('Valor do IPTU Por Ano')
    .nullable(),
  quartos : number()
    .label('Número de Quartos')
    .nullable(),
  suites : number()
    .label('Número de Suítes')
    .nullable(),
  banheiros : number()
    .label('Número de Banheiros')
    .nullable(),
  vagas : number()
    .label('Número de Vagas')
    .nullable(),
  ano_construcao: string()
    .label('Ano construção')
    .nullable(),
  andares: string()
    .label('Andar do Imóvel')
    .nullable(),
  area_util: string()
    .label('Área Útil(m²)')
    .nullable(),
  preco_metro_util: number()
    .label('Valor m² área total')
    .nullable(),
});


export const informacoesimovelAnalise = object()
.shape({
  endereco: string()
    .required('Obrigatório'),
  // cep: string()
  //   .required('Obrigatório'),
  cidade: string()
    .required('Obrigatório'),
  estado: string()
    .required('Obrigatório'),
  bairro: string(),
  tipo_negocio: mixed()
    .label('Tipo de negócio')
    .oneOf([...Object.keys(tipoNegocio)])
    .required('Obrigatório'),
  longitude: string()
    .label('Longitude'),
  latitude: string()
    .label('Latitude'),
  tipo_imovel: mixed()
    .label('Tipo de imóvel')
    .required('Obrigatório'),
  radius: mixed()
    .label('Raio de busca')
    .required('Obrigatório'),
  area: number()
    .label('Área (m2)')
    .nullable(),
  imprimir_links: boolean()
    .label('Imprimir Links das Amostras no PDF')
    .nullable(),
  exibir_historico: boolean()
    .label('Exibir Currículo no PDF')
    .nullable(),
  exibir_curriculo: boolean()
    .label('Imprimir Histórico da Imobiliária no PDF')
    .nullable(),
  exibir_imoveis_realizados: boolean()
    .label('Imprimir Imóveis Vendidos no PDF')
    .nullable(),
  exibir_amostras_na_rua: boolean()
    .label('Exibir Imóveis na rua')
    .nullable(),
  customRadio: mixed()
    .oneOf([
      'area_total',
      'area_util',
    ])
    .nullable(),
});

export const detalhesAnaliseMercado = object()
.shape({
  houve_vistoria: boolean()
    .label('Houve Vistoria no Imóvel?')
    .nullable(),
  data_avaliacao: string()
    .label('Data de Entrega da Avaliação')
    .nullable(),
  data_vistoria: string()
    .label('Data da Vistoria')
    .nullable(),
  solicitante: string()
    .label('Solicitante')
    .nullable(),
  valor_consideracao_corretor: string()
    .label('Percepção de Valor do Corretor')
    .nullable(),
  finalidade: string()
    .label('Finalidade')
    .nullable()
    .required('Obrigatório'),
});

export const criarAssinaturaUsuario = object()
.shape({
  metodo_pagamento: mixed()
    .label('Método de Pagamento')
    .oneOf([...Object.keys(metodoPagamento)])
    .required('Obrigatório'),
  user_id: string()
    .label('Cliente'),
  plano_id: number()
    .required('Obrigatório')
    .label('Plano de Assinatura'),
  data_expiracao: string()
    .label('Data e Hora Expiração')
    .nullable(),
});

export const criarUsuarioAdicional = object()
.shape({
  metodo_pagamento: mixed()
    .label('Método de Pagamento')
    .oneOf([...Object.keys(metodoPagamento)])
    .required('Obrigatório'),
  user_id: string()
    .label('Cliente'),
  plano_id: number()
    .required('Obrigatório')
    .label('Plano de Assinatura'),
  adicionais: number()
    .label('Quantidade')
    .nullable(),
  cobranca: boolean()
    .label('Gerar Cobrança?')
    .nullable(),
});

export const criarPacote = object()
.shape({
  metodo_pagamento: mixed()
    .label('Método de Pagamento')
    .oneOf([...Object.keys(metodoPagamento)])
    .required('Obrigatório'),
  user_id: string()
    .label('Cliente'),
  plano_id: number()
    .label('Pacote')
    .required('Obrigatório'),
});

export const pesquisaInteligente = object()
.shape({
  endereco: string()
    .required('Obrigatório'),
  // cep: string()
  //   .required('Obrigatório'),
  cidade: string()
    .required('Obrigatório'),
  estado: string()
    .required('Obrigatório'),
  bairro: string(),
  tipo_negocio: mixed()
    .label('Tipo de negócio')
    .oneOf([...Object.keys(tipoNegocio)])
    .required('Obrigatório'),
  longitude: string()
    .label('Longitude'),
  latitude: string()
    .label('Latitude'),
  tipo_imovel: mixed()
    .label('Tipo de imóvel')
    .required('Obrigatório'),
  radius: mixed()
    .label('Raio de busca')
    .required('Obrigatório'),
  area: number()
    .label('Área (m2)')
    .nullable(),
  imprimir_links: boolean()
    .label('Imprimir Links das Amostras no PDF')
    .nullable(),
  exibir_historico: boolean()
    .label('Exibir Currículo no PDF')
    .nullable(),
  exibir_curriculo: boolean()
    .label('Imprimir Histórico da Imobiliária no PDF')
    .nullable(),
  exibir_imoveis_realizados: boolean()
    .label('Imprimir Imóveis Vendidos no PDF')
    .nullable(),
  exibir_amostras_na_rua: boolean()
    .label('Exibir Imóveis na rua')
    .nullable(),
  customRadio: mixed()
    .oneOf([
      'customRadio2',
      'customRadio1',
    ])
    .nullable(),
  valor_divulgacao : number()
    .label('Adicionar valor de divulgação')
    .nullable(),
});

export const captacapDados = object()
.shape({
  endereco: string()
    .required('Obrigatório'),
  // cep: string()
  //   .required('Obrigatório'),
  cidade: string()
    .required('Obrigatório'),
  estado: string()
    .required('Obrigatório'),
  bairro: string(),
  tipo_negocio: mixed()
    .label('Tipo de negócio')
    .oneOf([...Object.keys(tipoNegocio)])
    .required('Obrigatório'),
  longitude: string()
    .label('Longitude'),
  latitude: string()
    .label('Latitude'),
  tipo_imovel: mixed()
    .label('Tipo de imóvel')
    .required('Obrigatório'),
  radius: mixed()
    .label('Raio de busca')
    .required('Obrigatório'),
  imprimir_links: boolean()
    .label('Imprimir Links das Amostras no PDF')
    .nullable(),
});

export const legenda = object()
.shape({
  legenda: string(),
  principal: boolean()
    .label('principal'),
});

export const plano = object()
.shape({
  nome: string()
    .label('Nome')
    .required('Obrigatório'),
  ordem: number()
    .label('Ordem')
    .nullable()
    .required('Obrigatório'),
  descricao: string()
    .label('Descrição')
    .required('Obrigatório'),
  tipo_plano: mixed()
    .label('Tipo de plano')
    .oneOf([...Object.keys(tipoPlano)])
    .required('Obrigatório'),
  valor_original: string()
    .label('Valor Original')
    .nullable()
    .required('Obrigatório'),
  valor: string()
    .label('Valor de venda')
    .nullable()
    .required('Obrigatório'),
  preco_sob_consulta: boolean()
    .label('Valor sob consulta')
    .nullable(),
  permite_logo_propria_pdf: boolean()
    .label('Logo própria nas impressões')
    .nullable(),
  permite_cor_rodape_pdf: boolean()
    .label('Personalizar a cor do rodapé nas impressões')
    .nullable(),
  permite_analise_valor_percepcao: boolean()
    .label('Inserir valor de percepção pessoal na análise mercadológica')
    .nullable(),
  inserir_amostras: boolean()
    .label('Inserir amostras manuais')
    .nullable(),
  acc_pai_gerencia: boolean()
    .label('Conta Pai gerenciar currículo e histórico')
    .nullable(),
  permite_assinatura_pdf: boolean()
    .label('Imagem de assinatura nas impressões')
    .nullable(),
  permite_impressao_data_pdf: boolean()
    .label('Impressão da data no PDF')
    .nullable(),
  inserir_marca_dagua: boolean()
    .label("Marca d'água nos PDFs de Análise, PTAM e Opinião de Mercado")
    .nullable(),
  valor_divulgacao: boolean()
    .label('Adicionar valor de divulgação')
    .nullable(),
  import_kendo: boolean()
    .label('Importar Base Kendo')
    .nullable(),
  permite_envio_pdf_por_email: boolean()
    .label('Envio de PDF por e-mail')
    .nullable(),
  imprimir_links: boolean()
    .label('Imprimir links')
    .nullable(),
  permite_analise_descricao_imovel: boolean()
    .label('Descrição do imóvel na Análise Mercadológica')
    .nullable(),
  historico_imobiliaria: boolean()
    .label('Adicionar histórico da imobiliária')
    .nullable(),
  imoveis_realizados: boolean()
    .label('Inserir imóveis vendidos')
    .nullable(),
  quantidade_avaliacoes : number()
    .label('Análises de Mercado')
    .nullable()
    .required('Obrigatório'),
  quantidade_laudos : number()
    .label('PTAMs')
    .nullable()
    .required('Obrigatório'),
  quantidade_opinioes : number()
    .label('Opiniões')
    .nullable()
    .required('Obrigatório'),
  quantidade_usuarios : number()
    .label('Usuários')
    .nullable(),
  quantidade_busca_engenheiro : number()
    .label('Buscas para Engenheiro')
    .nullable()
    .required('Obrigatório'),
  quantidade_pic : number()
    .label('Pesquisas Inteligente')
    .nullable()
    .required('Obrigatório'),
  periodo_minimo : number()
    .label('Período de Acesso (Em meses)')
    .nullable()
    .required('Obrigatório'),
  recorrente: boolean()
    .label('Plano Recorrente')
    .nullable(),
  trial: boolean()
    .label('Plano Trial')
    .nullable(),
});

export const amostraPtams = object()
.shape({
  exibir_amostras_na_rua: boolean()
  .label('Exibir Imóveis na rua')
  .nullable(),
  radius: mixed()
  .label('Raio de busca'),
});

export const novoCliente = object()
.shape({
  email: string()
    .label('E-mail')
    .email('E-mail inválido')
    .required('Preencha seu e-mail'),
  name: string()
    .label('Nome')
    .required('obrigatório'),
  password: string()
    .label('Nova senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Preencha sua senha'),
  password_confirmation: string()
    .label('Confirmar nova senha')
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Preencha sua senha'),
  cpf: string()
    .label('CPF')
    .required('Obrigatório'),
  telefone_celular: string()
    .label('Telefone')
    .required('O telefone é obrigatório'),
  tipo_pessoa: mixed()
    .oneOf([
      'Pessoa Física',
      'Pessoa Jurídica',
    ])
    .nullable()
});