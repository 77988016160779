<template>
  <div class="d-flex justify-content-between align-items-center">
    <h2 v-if="user">{{ user?.name }}</h2>
    <div class="d-flex align-items-end">
      <div 
        v-if="!user?.imagem_perfil" 
        class="user d-flex align-items-center"
      >
        <font-awesome-icon 
          icon="user"
        />
      </div>
      <div 
        v-else 
        class="pb-3 d-flex align-items-center"
      >
        <img 
          class="user"
          :src="user.imagem_perfil"
        >
      </div>
      <CDropdown>
        <CDropdownToggle class="user_configuracoes p-0">
          <font-awesome-icon icon="gear" />
        </CDropdownToggle>
        <CDropdownMenu class="menu">
          <CDropdownItem>
            <router-link to="/bairros" class="dropdown-item d-flex align-items-center">
              <font-awesome-icon icon="hand-holding-dollar" class="me-2" /> Financeiro
            </router-link>
          </CDropdownItem>
          <CDropdownItem>
            <router-link to="/perfil" class="dropdown-item d-flex align-items-center">
              <font-awesome-icon icon="user-gear" class="me-2" /> Perfil
            </router-link>
          </CDropdownItem>
          <CDropdownItem @click="logout">
            <font-awesome-icon icon="right-from-bracket" class="me-2" /> Sair
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/store/auth.store';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

const authStore = useAuthStore();
const router = useRouter();

const { user } = storeToRefs(authStore)

function logout(){
  authStore.logout()
  router.push('/login');
}
</script>

<style scoped>
.user{
  width: 80px;
  height: 80px;
  background-color: var(--cinza);
  border-radius: 50%;
  position: relative;
  left: 15px;
}

.user_configuracoes{
  background-color: transparent;
  border: none;
  color: #5F5F5F;
  font-size: 38px
}

.fa-user {
  color: var(--branco);
  font-size: 32px;
}

.menu a {
  width: 180px;
  padding: 2px 0;
}

.dropdown-toggle::after {
  display: none;
}
</style>