<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Meus usuários</h1>
      <router-link
        :to="{ path: '/usuarios/novoUsuario' }"
        class="btnRoxo"
      >
        Novo usuário
      </router-link>
    </div>
    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div>
          <label>Período</label>
          <input 
            id="period"
            v-model.trim="period"
            class="inputtext"
            name="period"
            type="month"
          >
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Último login</th>
            <th>Data de criação</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <template v-for="item in lista.users" :key="item.id">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ dateToField(item.last_login) }}</td>
              <td>{{ dateToField(item.created_at) }}</td>
              <td class="tdBtnAcao">
                <router-link
                  v-if="item.parent_id"
                  :to="{ 
                    name: 'Usuario Editar',
                    params: { usuarioId: item.id } 
                  }"
                  class="icon"
                >
                  <font-awesome-icon icon="pen-to-square" />
                </router-link>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div :class="!item.parent_id ? 'bordaAmarela' : 'bordaCinza'">
                  <div class="d-flex p-3 justify-content-around">
                    <div class="text-center analise">
                      <label>PTAMs Feitos</label>
                      <p>{{ item.countLaudos }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Análises Feitas</label>
                      <p>{{ item.countAnalises }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Opiniões de Mercado Feitas</label>
                      <p>{{ item.countOpinioes }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Pesquisas Inteligente Feitas</label>
                      <p>{{ item.countPics }}</p>
                    </div>
                  </div>
                  <div class="d-flex p-3 justify-content-around">
                    <div class="text-center analise">
                      <label>PTAMs Feitos {{ lista?.periodo }}</label>
                      <p>{{ item.countLaudosOnMonth }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Análises Feitas {{ lista?.periodo }}</label>
                      <p>{{ item.countAnalisesOnMonth }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Opiniões de Mercado Feitas {{ lista?.periodo }}</label>
                      <p>{{ item.countOpinioesOnMonth }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Pesquisas Inteligente Feitas {{ lista?.periodo }}</label>
                      <p>{{ item.countPicsOnMonth }}</p>
                    </div>
                  </div>
                  <div class="d-flex p-3 justify-content-around">
                    <div class="text-center analise">
                      <label>Buscas de PTAMs realizadas {{ lista?.periodo }}</label>
                      <p>{{ item.getCountRequestsPtam }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Buscas de Análises realizadas {{ lista?.periodo }}</label>
                      <p>{{ item.getCountRequestsAnalise }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Buscas de Opiniões de Mercado realizadas {{ lista?.periodo }}</label>
                      <p>{{ item.getCountRequestsOpiniao }}</p>
                    </div>
                    <div class="text-center analise">
                      <label>Buscas de Pesquisas Inteligente realizadas {{ lista?.periodo }}</label>
                      <p>{{ item.getCountRequestsPic }}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="6">
              <font-awesome-icon icon="spinner" class="spinner mt-3" />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="6">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.users.length">
            <td colspan="6">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { useMeusUsuariosStore } from '@/store/meusUsuarios.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const meusUsuariosStore = useMeusUsuariosStore()
const { lista, chamadasPendentes, erro } = storeToRefs(meusUsuariosStore);

const period = ref(route.query.period)

function atualizarUrl() {
  router.push({
    query: {
      ...route.query,
      period: period.value
    },
  });
}

watch([
  () => route.query.period,
], () => {
  meusUsuariosStore.$reset();
  meusUsuariosStore.buscarTudo({
    period: route.query.period || ''
  });
}, { immediate: true });

function limparFiltro(){
  router.push({
    query: ''
  });
   meusUsuariosStore.buscarTudo()
}
</script>

<style scoped>
.analise{
  width: 250px
}
</style>