<template> 
  <div>
    <div 
      v-if="emFoco" 
      class="py-3 px-3 bordaAmarela mb-3"
    >
      <p>{{ emFoco.tipo_imovel }}</p>
      <p>{{ emFoco.endereco }}</p>
      <p v-if="emFoco.area">
        Área Total: {{ emFoco.area }}m2
      </p>
    </div>
    <div class="tabela mb-3">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>
              Tipo
            </th>
            <th>
              Título
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('logradouro' || 'bairro')"
            >
              Endereço/Bairro
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('preco')"
            >
              Valor
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('area')"
            >
              Á. Priv.
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('area')"
            >
              Á. Total.
            </th>
            <th>
              R$/m² total
            </th>
            <th>
              R$/m² priv
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('anunciante')"
            >
              Anunciante
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in listaCalculo"
            :key="item.id"
          >
            <td>{{ item.tipo }}</td>
            <td class="titulo">{{ item.titulo }}</td>
            <td>{{ item.logradouro || item.bairro }}</td>
            <td>R${{ dinheiro(item.valor_imovel) }}</td>
            <td>{{ item.area_util + `m²` }}</td>
            <td>{{ item.area_total + `m²` }}</td>
            <td>R${{ dinheiro(item.valor_imovel / item.area_total) }}</td>
            <td>R${{ dinheiro(item.valor_imovel / item.area_util) }}</td>
            <td class="anunciante">{{ item.anunciante }}</td>
            <td class="tdBtnAcao">
              <button
                class="icon"
                arial-label="excluir"
                @click="excluirImovel(item.id)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <h2 class="my-3">Evolução dos cálculos - Análise Mercadológica</h2>
      <div class="py-3 px-3 bordaAmarela mb-3 d-flex justify-content-center align-items-center">
        <div>
          <p>Média aritmética =</p>
        </div>
        <div class="calculo px-3">
          <h3>
            {{ 
              emFoco?.usar_area === "area_util" ? 
                dinheiro(totalValorPorAreaConstruida) : 
                dinheiro(totalValorPorAreaTotal)
            }}
          </h3>
          <h3>{{ listaCalculo.length }}</h3>
        </div>
      </div>
      <small>Média aritmética = Soma do metro quadrado dividido pelo numero de amostras</small>
    </div>

    <div>
      <h2 class="my-3">Cálculo do Terreno</h2>
      <div class="py-3 px-3 bordaAmarela mb-3 d-flex justify-content-center align-items-center">
        <div>
          <div class="px-3">
            <p>valor do imóvel = {{ emFoco?.area }}m² x R${{ dinheiro(emFoco?.media_aritimetica) }}</p>
            <strong>Valor do imóvel = R${{ dinheiro(valorImovel)}}</strong>
          </div>
        </div>
      </div>
      <small>Valor do imóvel = area do imóvel vezes média aritmética</small>
    </div>

    <div class="d-flex flex-column align-items-center mt-4">
      <button 
        class="btnRoxo" 
        @click="mudarAba"
      >
        Salvar e continuar
      </button>
      <font-awesome-icon
        v-show="isSubmitting" 
        icon="spinner" 
        class="spinner mt-3"
      />
    </div>
  </div>
</template>

<script setup>
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useAnaliseMercadoStore } from '@/store/analiseMercado.store';
import { Tab } from 'bootstrap';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

const alertStore = useAlertStore();
const analiseMercadoStore = useAnaliseMercadoStore()
const { listaCalculo, emFoco, analiseId } = storeToRefs(analiseMercadoStore); 

const listaOrdenada = ref([]);
const colunaOrdenada = ref(null);
const direcaoOrdenada = ref(null);

const valorImovel = computed(() => {
    return emFoco.area_total * emFoco.media_aritimetica
  }
)
const totalValorPorAreaTotal = computed(() => {
  return listaCalculo.value.reduce((acc, emFoco) => {
    return acc + (emFoco.valor_imovel / emFoco.area_total);
  }, 0);
});

const totalValorPorAreaConstruida = computed(() => {
  return listaCalculo.value.reduce((acc, emFoco) => {
    return acc + (emFoco.valor_imovel / emFoco.area_util);
  }, 0);
});

async function excluirImovel(id) {
  alertStore.confirmAction('Deseja mesmo remover esse item?', async () => {
    if (await analiseMercadoStore.excluirAmostraRecalcular(id)) {
      analiseMercadoStore.buscarItem(analiseId.value);
      alertStore.success('Imóvel removido.');
    }
  }, 'Remover');
}

function sortTable(column) {
  if (colunaOrdenada.value === column) {
    direcaoOrdenada.value = direcaoOrdenada.value === 'asc' ? 'desc' : 'asc';
  } else {
    colunaOrdenada.value = column;
    direcaoOrdenada.value = 'asc';
  }

  listaOrdenada.value = listaCalculo.value.slice().sort((a, b) => {
    let valueA = a[column];
    let valueB = b[column];

    if (typeof valueA === 'string') {
      valueA = valueA.toUpperCase();
      valueB = valueB.toUpperCase();
    }

    if (direcaoOrdenada.value === 'asc') {
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    } else {
      return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
    }
  });
}

function mudarAba(){
  const tabElement = document.getElementById('Detalhe');
  const tabTrigger = new Tab(tabElement);
  tabTrigger.show();
}

watch(listaCalculo, () => {
  listaOrdenada.value = listaCalculo.value;
}, { immediate: true });
</script>

<style scoped>
.anunciante, .endereco, .titulo{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}

.ordenagem:hover::after {
  content: '▼';
  font-size: 12px;
  padding-left: 2px;
}

h2{
  color: var(--roxo)
}

.calculo{
  min-width: 200px; 
}

.calculo h3:first-child{
  border-bottom: 1px solid;
}
</style>