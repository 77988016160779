<template>
  <div class="card-branco">
    <router-link
      to="/usuarios"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <h1 class="mb-3">Selecione um novo plano de assinatura</h1>
    <section class="d-flex flex-wrap gap-3">
      <div 
        v-for="item in pacotes"
        :key="item.id"
      >
        <div class="bordaCinza w-100 p-3">
          <h2 class="align-items-center justify-content-center d-flex p-3">
            {{item.nome}}
          </h2>
          <div>
            <ul>
              <li>Paulo Roberto De Oliveir</li>
            </ul>
          </div>
          <div class="trabalhos">
            <div 
              class="py-1"
              v-if="item.quantidade_opinioes">
              <p>{{ item.quantidade_opinioes }} Opinião de mercado por mês</p>
            </div>
            <div 
              class="py-1"
              v-if="item.quantidade_avaliacoes">
              <p>{{ item.quantidade_avaliacoes }} Análise de mercado por mês</p>
            </div>
            <div 
              class="py-1"
              v-if="item.quantidade_laudos">
              <p>{{ item.quantidade_laudos }} PTAMS por mês</p>
            </div>
            <div 
              class="py-1"
              v-if="item.quantidade_pic">
              <p>{{ item.quantidade_pic }} Pesquisa inteligente por mês</p>
            </div>
            <div 
              class="py-1"
              v-if="item.quantidade_busca_engenheiro">
              <p>{{ item.quantidade_busca_engenheiro }} Captação de Dados Imobiliários</p>
            </div>
            <div 
              class="py-1"
              v-if="item.quantidade_usuarios">
              <p>{{ item.quantidade_usuarios }} Captação de Dados Imobiliários </p>
            </div>
          </div>
          <!-- <div>
            <p>Acesso por x meses</p>
          </div> -->
          <div>
            <div 
              v-if="item.preco_sob_consulta"
              class="align-items-center justify-content-center d-flex p-3"
            >
              <h1>Preço sob consulta</h1>
            </div>
            <div 
              v-if="item.valor"
              class="align-items-center justify-content-center flex-column d-flex p-3"
            >
              <div class="d-flex gap-1 pb-1">
                <p>12x</p>
                <h2>R${{ calcularParcelas(item.valor) }}</h2>
              </div>
              <small>á vista R${{ dinheiro(item.valor) }}</small>
            </div>
          </div>
          <div class="align-items-center justify-content-center d-flex p-3">
            <button class="btnAmarelo">
              Contratar plano
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import dinheiro from '@/helpers/dinheiro';
import { usePlanoStore } from '@/store/plano.store';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

const planoStore = usePlanoStore()
const { pacotes } = storeToRefs(planoStore);

const calcularParcelas = (valor) => {
  const valorNumerico = parseFloat(valor);
  return computed(() => {
    if (!valorNumerico || isNaN(valorNumerico)) {
      return "0,00";
    }
    return (valorNumerico / 12).toFixed(2).replace('.', ',');
  });
}

async function iniciar(){
 await planoStore.buscarPacotes()
}
iniciar()
</script>

<style scoped>
.trabalhos p{
  border-bottom: 1px solid var(--cinza);
  padding-bottom: 8px;
}
</style>